<template>
  <ec-app-store-page-wrapper>
    
    <template v-slot:content>
      
      <ec-app-store-banner/>
      
      <ec-container fluid>
        <ec-row class="align-center justify-center ec-width-1200">
          <ec-col cols="12" class="ec-mt-2">
            <h1 class="ec-mb-2">{{ $store.getters.getTranslation('app_title') }}</h1>
          </ec-col>
        </ec-row>
        
        <ec-app-store-browser-search-filters />
        
        <ec-app-store-browser-search
            v-if="$store.getters.searchFieldGetter || $store.getters.appliedFiltersGetter.length > 0 && $store.getters.appliedFiltersGetter.some(filter => Object.values(filter)[0] !== null)"/>
        
        <!-- App store main groups (if no search / filters) -->
        <ec-app-store-browser-groups v-else/>
      
      </ec-container>
    
    </template>
  </ec-app-store-page-wrapper>
</template>

<script>

import EcAppStoreBanner from '@/pages/components/EcAppStoreBanner.vue'
import EcAppStoreBrowserGroups from '@/pages/components/EcAppStoreBrowserGroups.vue'
import EcAppStorePageWrapper from '@/pages/components/EcAppStorePageWrapper.vue'
import EcAppStoreBrowserSearchFilters from '@/pages/components/EcAppStoreBrowserSearchFilters.vue'
import EcAppStoreBrowserSearch from '@/pages/components/EcAppStoreBrowserSearch.vue'

export default {
  components: {EcAppStoreBrowserSearch, EcAppStoreBrowserSearchFilters, EcAppStorePageWrapper, EcAppStoreBrowserGroups, EcAppStoreBanner},
}

</script>

<style lang="scss">

.ec-width-1200 {
  //max-width: 1200px;
  margin: 0 auto !important;
}

</style>

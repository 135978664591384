import {AbstractModel} from './AbstractModel'
import {AppFacilitatorModel} from './AppFacilitatorModel'
import {AppMarketplaceModel} from './AppMarketplaceModel'
import {AppModuleModel} from './AppModuleModel'

export class AppModel extends AbstractModel {

    id
    name
    status
    type
    category
    isBeta
    logoUrl
    subtitle
    shortDescription
    facilitator
    facilitatorAnnouncement
    industries
    optionalInformation
    contactInformation
    usps
    marketplace
    module
    ctaButtonText
    ctaButtonLink
    ctaType
    media
    shopSubscriptionType
    channelCredits
    countries

    tags

    constructor(data = {}) {
        super()
        this.setProperty('id', data.id, 0)
        this.setProperty('name', data.name, '')
        this.setProperty('status', data.status, '')
        this.setProperty('type', data.type, '')
        this.setProperty('isBeta', data.isBeta, false)
        this.setProperty('marketplace', data.marketplace, '')
        this.setProperty('module', data.module, '')
        this.setProperty('category', data.category, '')
        this.setProperty('logoUrl', data.logoUrl, '')
        this.setProperty('subtitle', data.subtitle, '')
        this.setProperty('shortDescription', data.shortDescription, '')
        this.setProperty('industries', data.industries, [])
        this.setProperty('optionalInformation', data.optionalInformation, {})
        this.setProperty('facilitatorAnnouncement', data.facilitatorAnnouncement, '')
        this.setProperty('contactInformation', data.contactInformation, '')
        this.setProperty('usps', data.usps, [])
        this.setProperty('ctaButtonText', data.ctaButtonText, '')
        this.setProperty('ctaButtonLink', data.ctaButtonLink, '')
        this.setProperty('ctaType', data.ctaType, '')
        this.setProperty('media', data.media, [])
        this.setProperty('shopSubscriptionType', data.shopSubscriptionType, '')
        this.setProperty('channelCredits', data.channelCredits, 0)
        this.setProperty('tags', data.tags, [])
        this.setCountries(data.countries)
        this.setFacilitator(data.facilitator)
        this.setMarketplace(data.marketplace)
        this.setModule(data.module)

    }

    setFacilitator(facilitatorData) {
        if (facilitatorData) {
            this.facilitator = new AppFacilitatorModel(facilitatorData)
        }
    }

    setMarketplace(marketplaceData) {
        if (marketplaceData) {
            this.marketplace = new AppMarketplaceModel(marketplaceData)
        }
    }

    setModule(moduleData) {
        if (moduleData) {
            this.module = new AppModuleModel(moduleData)
        }
    }

    setCountries(countriesData) {
        let countries = []
        if (countriesData) {
            Object.values(countriesData).forEach((country, index) => {
                countries.push({key: Object.keys(countriesData)[index], value: country})
            })
        }
        this.countries = countries
    }

}

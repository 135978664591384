import {AbstractModel} from './AbstractModel'
export class GroupInformationModel extends AbstractModel {

    languageCode
    name
    description

    constructor(data = {}) {
        super()
        this.setProperty('languageCode', data.languageCode, '')
        this.setProperty('name', data.name, '')
        this.setProperty('description', data.description, '')
    }

}

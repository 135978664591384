import {AbstractModel} from './AbstractModel'
import {CardModel} from './CardModel'

export class SearchResultsModel extends AbstractModel {

    relatedApps

    constructor(data = {}) {
        super()
        this.setRelatedApps(data)
    }

    setRelatedApps(data) {
        let relatedApps = []
        if (data) {
            data.forEach((app) => {
                if (Object.prototype.hasOwnProperty.call(app, 'type')) {
                    relatedApps.push(new CardModel(app))
                }
            })
        }
        this.relatedApps = relatedApps
    }

    hasRelatedApps() {
        return this.relatedApps.length > 0
    }

}

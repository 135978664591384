<template>
  <div class="ec-pb-3">
    <ec-h3>{{ $store.getters.getTranslation('app_detail_short_description_title') }}</ec-h3>
    <div v-html="$store.getters.getAppDetailField($route.params.id, 'shortDescription')"></div>
  </div>
</template>

<script>

export default {}

</script>

import {AbstractModel} from './AbstractModel'

export class AppPropertyTranslationModel extends AbstractModel {

    name
    languageCode

    constructor(data = {}) {
        super()
        this.setProperty('name', data.name, '')
        this.setProperty('languageCode', data.languageCode, '')
    }

}

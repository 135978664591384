<template>
  <div class="ec-app-complete-information">
    <ec-row class="ec-mt-2">
      
      <ec-col cols="12" class="col-sm-12 col-md-3 col-lg-3 col-xl-3 ec-app-store-border-right">
        <!-- USP list component -->
        <ec-app-complete-information-usps/>
        
        <!-- Facilitator component -->
        <ec-app-complete-information-facilitator/>
        
        <!-- Contact information component -->
        <ec-app-complete-information-contact-information/>
      </ec-col>
      
      <ec-col cols="12" class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <!-- Media carousel component -->
        <ec-app-media-carousel v-if="$store.getters.getAppDetailField($route.params.id, 'media').length > 0" class="ec-mb-4" prepend="/images/apps/media/" :images="$store.getters.getAppDetailField($route.params.id, 'media')"/>
        
        <!-- Short description component -->
        <ec-app-complete-information-short-description />
        
        <!-- Optional tabs with information -->
        <ec-app-complete-information-optional-information />
      </ec-col>
    
    </ec-row>
  </div>
</template>

<script>

import EcAppCompleteInformationUsps from './EcAppCompleteInformationUsps.vue'
import EcAppCompleteInformationFacilitator from './EcAppCompleteInformationFacilitator.vue'
import EcAppCompleteInformationContactInformation from './EcAppCompleteInformationContactInformation.vue'
import EcAppCompleteInformationShortDescription from './EcAppCompleteInformationShortDescription.vue'
import EcAppCompleteInformationOptionalInformation from "./EcAppCompleteInformationOptionalInformation.vue"
import EcAppMediaCarousel from "./EcAppMediaCarousel.vue"

export default {
  
  components: {EcAppCompleteInformationOptionalInformation, EcAppCompleteInformationShortDescription, EcAppCompleteInformationContactInformation, EcAppCompleteInformationFacilitator, EcAppCompleteInformationUsps, EcAppMediaCarousel}
  
}

</script>

<template>
  <div v-if="tabItems.length > 0 && $store.getters.getAppDetailField($route.params.id, 'optionalInformation')">
    <ec-tabs-nested :tabs="tabItems" :use-url="false"/>
  </div>
</template>

<script>


import EcHtmlTabComponent from '@/pages/detail/components/tabs/EcHtmlTabComponent.vue'
import EcArrayTabComponent from '@/pages/detail/components/tabs/EcArrayTabComponent.vue'

export default {
  
  computed: {
    tabItems() {
      let tabs = []
      let informationFieldHeaders = Object.keys(this.$store.getters.getAppDetailField(this.$route.params.id, 'optionalInformation'))
      Object.values(this.$store.getters.getAppDetailField(this.$route.params.id, 'optionalInformation')).forEach((optionalField, index) => {
        // If optional field is Array
        if (Array.isArray(optionalField)) {
          // If optional field is custom (title + value)
          if (informationFieldHeaders[index] === 'custom_optional_fields') {
            optionalField.forEach(customOptionalField => {
              tabs.push({
                title: customOptionalField.title,
                component: EcHtmlTabComponent,
                data: {
                  html: customOptionalField.value
                }
              })
            })
          } else {
            if (informationFieldHeaders[index] === 'functionalities_and_processes' && optionalField.length > 0) {
              // If optional field is 'functionalities_and_processes', because is peculiar Array with ordering
              tabs.push({
                title: this.$store.getters.getTranslation('app_detail_optional_fields_header_' + informationFieldHeaders[index]),
                component: EcArrayTabComponent,
                data: {
                  array: optionalField
                }
              })
            }
          }
        } else if (optionalField) {
          // If optional field is not an Array and custom HTML code
          tabs.push({
            title: this.$store.getters.getTranslation('app_detail_optional_fields_header_' + informationFieldHeaders[index]),
            component: EcHtmlTabComponent,
            data: {
              html: optionalField
            }
          })
        }
      })
      return tabs
    }
  }
}

</script>

<style lang="scss">

h1 {
  font-family: $ec-heading-font-family;
  font-size: $ec-font-size-1;
  font-weight: $ec-heading-font-weight-bold;
  color: var(--v-text-base);
}

h2 {
  font-family: $ec-heading-font-family;
  font-size: $ec-font-size-2;
  font-weight: $ec-heading-font-weight-bold;
  color: var(--v-text-base);
}

h3 {
  font-family: $ec-heading-font-family;
  font-size: $ec-font-size-3;
  font-weight: $ec-heading-font-weight-bold;
  color: var(--v-text-base);
}

li {
  p {
    margin-bottom: 0 !important;
  }
}

</style>

<template>
  <ec-container fluid>
    <ec-row>
      <ec-col cols="12">
        <ec-card>
          <ec-card-text>
            <slot/>
          </ec-card-text>
        </ec-card>
      </ec-col>
    </ec-row>
  </ec-container>
</template>

<script>
export default {}
</script>
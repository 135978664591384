import {AbstractModel} from './AbstractModel'

export class SlideActionModel extends AbstractModel {

   title
   value
   type
    constructor(data = {}) {
        super()
        this.setProperty('title', data.title, '')
        this.setProperty('value', data.value, '')
        this.setProperty('type', data.type, '')
    }

}

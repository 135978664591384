<template>
  <div>
    <ec-row class="ec-px-3">
      <ec-col cols="12" class="ec-mx-0 ec-px-2 col-xl-2 col-lg-2 col-md-3 col-sm-6"
              v-for="(app, index) in group.relatedApps" :key="index + '_apps'">
        <div class="ec-app-store-browser-app-card d-flex flex-column justify-space-between align-center">
          <div class="ec-width-100">
            <div class="ec-mb-3 ec-mx-2">
              <v-img class="ec-app-logo" contain aspect-ratio="2" :src="appLogo(app.logoUrl)" :alt="$store.getters.getAltText(app.name)"
                     @click="$router.push({name: 'EcExternalAppDetail', params: {id: app.id, name: app.name, formattedAppName: app.name.toLowerCase().replace(/\s/g,'–').replace(',', '')}})"/>
            </div>
            <div v-if="app.countryCodes.length > 0"
                 class="ec-mb-4 ec-subtypes d-flex align-center justify-center flex-wrap">
              <div v-for="(countryCode, index) in app.countryCodes" :key="index">
                <ec-tooltip
                    :text="getTooltipText(app.id, countryCode).startsWith('translate.') ? $store.getters.getTranslation(getTooltipText(app.id, countryCode)) : getTooltipText(app.id, countryCode)">
                  <template v-slot:activator>
                    <ec-img class="ec-ma-1"
                            :alt="$store.getters.getAltText(countryCode + '–flag')"
                            :src="require('../../assets/images/flags/' + countryCode.toLowerCase() + '.png')"
                            max-height="12"
                            max-width="20"/>
                  </template>
                </ec-tooltip>
              </div>
            </div>
            <div>
              <ec-h3>{{ app.name }}</ec-h3>
              <p class="ec-mb-0">{{ app.information.subtitle }}</p>
            </div>
          </div>
          <div>
            <div class="ec-my-4">
              <ec-chip color="primary">{{ app.category.name }}</ec-chip>
            </div>
            <div>
              <ec-btn color="primary" outlined exact append-icon="icon-ec-arrow-right"
                      :to="{name: 'EcExternalAppDetail', params: {id: app.id, name: app.name, formattedAppName: app.name.toLowerCase().replace(/\s/g,'–').replace(',', '')}}">
                {{ $store.getters.translationsGetter['view_app_detail'][$store.getters.languageGetter] }}
              </ec-btn>
            </div>
          </div>
        </div>
      </ec-col>
    </ec-row>
  
  </div>
</template>

<script>

export default {
  
  props: {
    group: {
      type: Object,
      required: false
    }
  },
  
  methods: {
    appLogo(logoUrl) {
      return '/images/apps/logos/' + logoUrl
    },
    getTooltipText(id, countryCode) {
      let text = this.$store.getters.getAppDetailField(id, 'countries').find(country => country.key === countryCode)['value']
      return text.startsWith('translate.') ? this.$store.getters.getTranslation(text) : text
    }
  }
  
}

</script>

<style lang="scss" scoped>

.ec-app-store-browser-app-card::v-deep {
  padding: $ec-spacing-3;
  border-radius: $ec-default-border-radius;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  text-align: center;
  min-height: 100%;
  
  &.ec-channel-inactive {
    background: var(--v-ec_background_table-base);
    box-shadow: none;
    border: 1px solid #CCCCCC;
    
    .ec-not-available, .ec-info {
      text-align: center;
      font-size: $ec-font-size-6;
      font-weight: $ec-body-font-weight-light;
      color: var(--v-text-lighten1);
    }
  }
}

.ec-subtypes {
  text-align: center;
  line-height: 18px;
  height: 18px;
  font-size: $ec-font-size-6;
  color: var(--v-text-lighten1);
  text-transform: uppercase;
}

.ec-app-logo {
  cursor: pointer;
}

</style>

<script>
import { VCard } from 'vuetify/lib'
import EcIcon from '@/components/EcIcon'

export default {
  extends: VCard,

  props: {
    outlined: {
      default: true,
    },
    onIcon: {
      tile: true,
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    noShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    subtle: {
      type: Boolean,
      required: false,
      default: false,
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    classes () {
      return {
        ...VCard.options.computed.classes.call(this),
        'ec-card': true,
        'ec-no-padding': this.noPadding,
        'ec-no-shadow': this.noShadow,
        'ec-no-border': this.noBorder,
        'ec-subtle': this.subtle,
        'ec-active': this.active,
        'ec-gradient': this.dark,
      }
    }
  },

  render (h) {
    const { tag, data } = this.generateRouteLink()

    data.style = this.styles

    if (this.isClickable) {
      data.attrs = data.attrs || {}
      data.attrs.tabindex = 0
    }

    let elements = []
    if (this.active) {
      elements.push(this.$createElement(EcIcon, {staticClass: 'ec-card-active-icon'}, 'icon-ec-success'))
    }
    elements.push(this.genProgress())
    elements.push(this.$slots.default)

    return h(tag, this.setBackgroundColor(this.color, data), elements)
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/overrides/VCard.scss';
</style>

<template>
  <ec-app-store-page-wrapper>
    <template v-slot:content>
      <ec-container fluid>
        <ec-row class="align-center justify-center ec-width-1200">
          <ec-col xs="12" sm="12" md="12" lg="12" class="col-12 ec-mt-2">
            
            <div :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-space-between align-center justify-center' : 'd-flex flex-column-reverse'">
              <h1 class="ec-mb-2">{{ $store.getters.getTranslation('app_title') }}</h1>
              <ec-btn class="ec-mb-2" color="primary" exact outlined prepend-icon="icon-ec-arrow-left"
                      :to="{name: 'ExternalAppStore'}">
                {{ $store.getters.getTranslation('back_to_store_button_text') }}
              </ec-btn>
            </div>
            
            <ec-card class="ec-pa-3 ec-mb-4">
              <!-- All Basic app information -->
              <ec-app-basic-information/>
              
              <!-- More extensive app information -->
              <ec-app-complete-information/>
            </ec-card>
          </ec-col>
        </ec-row>
      </ec-container>
    </template>
  </ec-app-store-page-wrapper>
</template>

<script>

import EcAppBasicInformation from './components/EcAppBasicInformation.vue'
import EcAppStorePageWrapper from '@/pages/components/EcAppStorePageWrapper.vue'
import EcAppCompleteInformation from './components/EcAppCompleteInformation.vue'

export default {
  
  components: {EcAppStorePageWrapper, EcAppBasicInformation, EcAppCompleteInformation},
  
  mounted() {
    window.scrollTo(0, 0)
  }
  
}

</script>

<style lang="scss" scoped>

.ec-width-1200 {
  //max-width: 1200px;
  margin: 0 auto !important;
}

.ec-card::v-deep {
  
  .ec-app-store-border-right {
    border-right: 1px solid #ECECEC;
  }
  
  .ec-app-store-border-bottom {
    border-bottom: 1px solid #ECECEC;
  }
}

</style>

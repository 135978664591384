<template>
  <div>
    <div class="ec-app-store-optional-tab" v-if="tab.data.html" v-html="tab.data.html"></div>
  </div>
</template>

<script>

export default {
  
  props: {
    tab: {
      required: true,
      type: Object
    }
  }
  
}

</script>

<style lang="scss" scoped>

.ec-app-store-optional-tab::v-deep {
  
  p {
    font-weight: 400;
  }
  
}

</style>

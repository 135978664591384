import { render, staticRenderFns } from "./EcLink.vue?vue&type=template&id=cf6b9cd6&scoped=true&"
import script from "./EcLink.vue?vue&type=script&lang=js&"
export * from "./EcLink.vue?vue&type=script&lang=js&"
import style0 from "./EcLink.vue?vue&type=style&index=0&id=cf6b9cd6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf6b9cd6",
  null
  
)

export default component.exports
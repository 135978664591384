<template>
  <h3 class="ec-h3"><slot/></h3>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.ec-h3 {
  font-family: $ec-heading-font-family;
  font-weight: $ec-heading-font-weight-semi-bold;
  line-height: $ec-font-size-3;
  font-size: $ec-font-size-3;
  margin: 0 0 $ec-spacing-2 0;
  padding: 0;
  color: var(--v-accent-base);
}
</style>

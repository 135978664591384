<script>
import {VProgressCircular} from 'vuetify/lib'

export default {
  extends: VProgressCircular,

  props: {
    indeterminate: {
      default: true
    },
    color: {
      default: 'accent lighten-5'
    },
    size: {
      default: 18
    },
    width: {
      default: 3
    }
  },

  computed: {
    classes () {
      return {
        ...VProgressCircular.options.computed.classes.call(this),
        'ec-progress-circular': true
      }
    }
  }
}
</script>
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ecTheme from './theme'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            checkboxIndeterminate: 'icon-ec-checkbox-indeterminate',
            checkboxOff : 'icon-ec-checkbox-blank',
            checkboxOn  : 'icon-ec-checkbox-filled',
            radioOff    : 'icon-ec-radio-blank',
            radioOn     : 'icon-ec-radio-filled',
            success     : 'icon-ec-success-filled',
            info        : 'icon-ec-info-filled',
            warning     : 'icon-ec-warning-triangle-filled',
            error       : 'icon-ec-warning-triangle-filled',
            dropdown    : 'icon-ec-down',
            delete      : 'icon-ec-close', // delete (e.g. v-chip close)
            subgroup    : 'icon-ec-down',
            loading     : 'icon-ec-loader',
            expand      : 'icon-ec-down', // mdi-chevron-down (e.g. v-expansion-panel)
            clear       : 'icon-ec-close-filled', // mdi-close (e.g. v-select clearable)
            sort        : 'icon-ec-down', // mdi-arrow-up (e.g. v-data-table sortable header columns)
            prev        : 'icon-ec-left', // 'mdi-chevron-left' (e.g. v-tabs)
            next        : 'icon-ec-right', // 'mdi-chevron-right' (e.g. v-tabs)

            // complete: 'mdi-check',
            // cancel: 'mdi-close-circle',
            // close: 'mdi-close',
            // delimiter: 'mdi-circle', // for carousel
            // menu: 'mdi-menu',
            // edit: 'mdi-pencil',
            // ratingEmpty: 'mdi-star-outline',
            // ratingFull: 'mdi-star',
            // ratingHalf: 'mdi-star-half-full',
            // first: 'mdi-page-first',
            // last: 'mdi-page-last',
            // unfold: 'mdi-unfold-more-horizontal',
            // file: 'mdi-paperclip',
            // plus: 'mdi-plus',
            // minus: 'mdi-minus',
        },
    },

    theme: {
        options: {
            // customProperties makes sure we can use CSS variables like var(--v-primary-base).
            // Note: Custom properties are not natively supported in Internet Explorer. Polyfills are available—with some limitations—for Internet Explorer 11:
            // https://vuetifyjs.com/en/features/theme/#custom-properties
            customProperties: true
        },
        themes: {
            light: ecTheme
        }
    }
})

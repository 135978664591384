<template>
  <ec-col cols="12" class="col-sm-3 col-md-3 col-lg-2 col-xl-2 ec-app-store-border-right d-flex align-center">
    <div class="ec-app-logo" :style="backgroundImageStyling" />
  </ec-col>
</template>

<script>

export default {
  
  computed: {
    image() {
      return '/images/apps/logos/' + this.$store.getters.getAppDetailField(this.$route.params.id, 'logoUrl')
    },
    backgroundImageStyling() {
      return 'background: url("' + this.image + '"); background-size: contain; background-position: center; background-repeat: no-repeat;'
    }
  }
  
}

</script>

<style lang="scss" scoped>

.ec-app-logo {
  width: 100%;
  padding-bottom:50%;
  position: relative;
}

</style>

import colors from '@/util/ec_colors'

export default {

    // Default Vuetify colors.

    // primary: string
    // secondary: string
    // accent: string,
    // error: string,
    // info: string,
    // success: string,
    // warning: string,

    // base: string
    // lighten5: string
    // lighten4: string
    // lighten3: string
    // lighten2: string
    // lighten1: string
    // darken1: string
    // darken2: string
    // darken3: string
    // darken4: string

    primary: {
        lighten6: colors.primary.light2,
        lighten5: colors.primary.light10,
        lighten4: colors.primary.light20,
        lighten3: colors.primary.light50,
        base: colors.primary.base,
        darken1: colors.primary.dark10,
    },

    info: {
        lighten5: colors.primary.light10,
        lighten4: colors.primary.light20,
        lighten3: colors.primary.light50,
        base: colors.primary.base,
        darken1: colors.primary.dark10,
    },

    accent: {
        lighten5: colors.accent.light10,
        lighten4: colors.accent.light20,
        lighten3: colors.accent.light50,
        base: colors.accent.base,
        darken1: colors.accent.dark10,
    },

    success: {
        lighten6: colors.success.light2,
        lighten5: colors.success.light10,
        lighten4: colors.success.light25,
        lighten3: colors.success.light40,
        base: colors.success.base,
        darken1: colors.success.dark10,
    },

    error: {
        lighten6: colors.error.light2,
        lighten5: colors.error.light10,
        lighten4: colors.error.light20,
        lighten3: colors.error.light50,
        base: colors.error.base,
        darken1: colors.error.dark10,
    },

    warning: {
        lighten5: colors.warning.light10,
        lighten4: colors.warning.light25,
        lighten3: colors.warning.light50,
        base: colors.warning.base,
        darken1: colors.warning.dark10,
    },

    text: {
        lighten5: colors.shades.white,
        lighten3: colors.shades.base,
        lighten1: colors.shades.dark,
        base: colors.shades.darker,
    },

    // Custom colors.

    ec_border: {
        lighten5: colors.shades.light,
        base: colors.shades.base,
    },

    ec_background: {
        lighten5: colors.shades.white,
        lighten4: colors.shades.lighter,
        base: colors.shades.light,
        darken2: colors.shades.base,
    },

    ec_background_table: {
        base: colors.backgrounds.table,
    },

    ec_background_element: {
        base: colors.backgrounds.element,
    },

    ec_icon: {
        lighten5: colors.shades.white,
        lighten3: colors.shades.base,
        base: colors.shades.dark,
    },

    // Dark theme colors (for side menu)

    ec_dark_background: {
        lighten5: colors.backgrounds.table,
        lighten1: colors.shades.dark,
        base: colors.shades.darker,
        darken4: colors.shades.black,
    },

    ec_dark_icon: {
        lighten5: colors.shades.white,
        base: colors.shades.dark,
    },

    ec_dark_text: {
        base: colors.shades.white,
    },

    ec_dark_blue: {
        base: colors.darkblue.base,
    },

}
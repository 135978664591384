<template>
  <ec-expansion-panels v-model="panel" class="ec-mb-2" flat>
    <ec-expansion-panel @change="cancel">
      <ec-expansion-panel-header>
        <div class="d-flex justify-start">
          <div class="ec-filter-button">
            <ec-icon size="18">icon-ec-filter</ec-icon>
            <div>{{ $store.getters.getTranslation('app_store_filters_title') }}</div>
          </div>
          <slot name="prepend-filters"></slot>
          <div class="ec-active-filters">
            <div v-for="(filterValue, index) in appliedFilterValues" :key="index">
              <ec-chip v-if="Object.values(filterValue)[0]" @click:close="remove(Object.keys(filterValue)[0])"
                       :close="true">
                {{
                  $store.getters.getTranslation('filter_name_' + Object.keys(filterValue)[0]) + ': ' + returnValues(filterValue)
                }}
              </ec-chip>
            </div>
            <ec-link v-if="appliedFilterValues.some(filter => Object.values(filter)[0] !== null)" @click.stop="reset">
              {{ $store.getters.getTranslation('app_store_reset_filters') }}
            </ec-link>
          </div>
        </div>
      </ec-expansion-panel-header>
      <ec-expansion-panel-content>
        <div class="ec-panel-filters">
          <ec-row>
            <ec-col cols="12" class="col-sm-6 col-md-3 col-lg-3 col-xl-3 ec-col-filter">
              <span class="ec-filter-group-title">{{
                  $store.getters.getTranslation('filter_name_categoryFilter')
                }}</span>
              <ec-select-single :value="$store.getters.categoryFilterGetter"
                                @input="$store.commit('setFilter', {name: 'categoryFilter', value: $event})"
                                :items="categoryFilterValues"
                                :label="$store.getters.getTranslation('filter_single_select_placeholder')"/>
            </ec-col>
            <ec-col cols="12" class="col-sm-6 col-md-3 col-lg-3 col-xl-3 ec-col-filter">
              <span class="ec-filter-group-title">{{
                  $store.getters.getTranslation('filter_name_countryFilter')
                }}</span>
              <ec-select-single :value="$store.getters.countryFilterGetter"
                                @input="$store.commit('setFilter', {name: 'countryFilter', value: $event})"
                                :items="countryFilterValues"
                                :label="$store.getters.getTranslation('filter_single_select_placeholder')"/>
            </ec-col>
            <ec-col cols="12" class="col-sm-6 col-md-3 col-lg-3 col-xl-3 ec-col-filter">
              <span class="ec-filter-group-title">{{
                  $store.getters.getTranslation('filter_name_industryFilter')
                }}</span>
              <ec-select-single :value="$store.getters.industryFilterGetter"
                                @input="$store.commit('setFilter', {name: 'industryFilter', value: $event})"
                                :items="industryFilterValues"
                                :label="$store.getters.getTranslation('filter_single_select_placeholder')"/>
            </ec-col>
            <ec-col class="ec-panel-actions">
              <ec-card-actions>
                <ec-btn color="error" outlined @click="cancel">{{ $store.getters.getTranslation('app_store_cancel') }}
                </ec-btn>
                <ec-btn color="primary" @click="apply">{{ $store.getters.getTranslation('app_store_apply_filters') }}
                </ec-btn>
              </ec-card-actions>
            </ec-col>
          </ec-row>
        </div>
      </ec-expansion-panel-content>
    </ec-expansion-panel>
  </ec-expansion-panels>
</template>

<script>

import EcExpansionPanels from '@/components/EcExpansionPanels.vue'
import EcExpansionPanel from '@/components/EcExpansionPanel.vue'
import EcExpansionPanelHeader from '@/components/EcExpansionPanelHeader.vue'
import EcIcon from '@/components/EcIcon.vue'
import EcLink from '@/components/EcLink.vue'
import EcExpansionPanelContent from '@/components/EcExpansionPanelContent.vue'
import EcRow from '@/components/EcRow.vue'
import EcCol from '@/components/EcCol.vue'
import EcCardActions from '@/components/EcCardActions.vue'
import EcBtn from '@/components/EcBtn.vue'
import EcSelectSingle from '@/components/EcSelectSingle.vue'

export default {
  components: {EcSelectSingle, EcBtn, EcCardActions, EcCol, EcRow, EcExpansionPanelContent, EcLink, EcIcon, EcExpansionPanelHeader, EcExpansionPanel, EcExpansionPanels},
  
  data() {
    return {
      panel: '',
      appliedFilterValues: this.$store.getters.filtersGetter
    }
  },
  
  computed: {
    countryFilterValues() {
      let countries = []
      this.$store.getters.translatedAppsData.data.forEach(app => {
        Object.values(app.countries).forEach((country, index) => {
          countries.push({
            'text': country.startsWith('translate.') ? this.$store.getters.getTranslation(country) : country,
            'value': Object.keys(app.countries)[index]
          })
        })
      })
      return countries
    },
    categoryFilterValues() {
      let categories = []
      this.$store.getters.translatedAppsData.data.forEach(app => {
        categories.push({
          'text': app.category,
          'value': app.category
        })
      })
      return categories
    },
    industryFilterValues() {
      let industries = []
      this.$store.getters.translatedAppsData.data.forEach(app => {
        app.industries.forEach(industry => {
          industries.push({
            'text': industry,
            'value': industry
          })
        })
      })
      return industries
    }
  },
  
  methods: {
    apply() {
      this.panel = null
      this.$store.commit('setAppliedFilterValues')
    },
    
    cancel() {
      this.panel = null
    },
    
    remove(filterValue) {
      this.$store.commit('setFilter', {name: filterValue, value: null})
      this.$store.commit('setAppliedFilterValues')
    },
    
    reset() {
      this.$store.getters.filtersGetter.forEach(filter => {
        this.$store.commit('setFilter', {name: Object.keys(filter)[0], value: null})
      })
      this.$store.commit('setAppliedFilterValues')
    },
    
    returnValues(filterValue) {
      let prefix = Object.keys(filterValue)[0]
      let values = {
        countryFilter: this.countryFilterValues,
        categoryFilter: this.categoryFilterValues,
        industryFilter: this.industryFilterValues
      }
      let value = values[prefix].find(object => object.value === Object.values(filterValue)[0]).text
      return value.startsWith('translate.') ? this.$store.getters.getTranslation(value) : value
    },
  }
  
}

</script>

<style lang="scss" scoped>
.v-expansion-panels {
  border: none;
  
  .v-expansion-panel {
    border: 1px solid var(--v-ec_border-base);
    
    .v-expansion-panel-header {
      font-size: $ec-font-size-4;
      padding: calc(10px - 3px) calc(20px - 3px);
      
      .ec-active-filters > * {
        margin: 3px;
        display: inline-flex;
      }
      
      .ec-filter-button {
        font-family: $ec-heading-font-family;
        font-weight: $ec-heading-font-weight-bold;
        display: flex;
        align-items: center;
        margin-right: $ec-spacing-2;
        
        .v-icon {
          margin-right: $ec-spacing-2;
        }
      }
      
      &:focus::before {
        opacity: 0;
      }
    }
    
    .v-expansion-panel-content::v-deep {
      .v-expansion-panel-content__wrap {
        padding: $ec-spacing-3;
        width: 100%;
        
        .ec-panel-filters {
          display: flex;
          flex-wrap: wrap;
          
          .ec-col-filter:not(:nth-of-type(4n)) {
            border-right: 1px solid var(--v-ec_border-lighten5);
          }
          
          .col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            
            & > .v-input {
              flex: none;
            }
          }
        }
        
        .ec-filter-group-title {
          font-family: $ec-heading-font-family;
          font-weight: $ec-heading-font-weight-bold;
          margin-bottom: $ec-spacing-2;
        }
        
        .ec-panel-actions {
          align-self: flex-end;
        }
      }
    }
    
    &--active {
      border: 1px solid var(--v-primary-base);
      box-shadow: $ec-box-shadow;
      
      .v-expansion-panel-header::v-deep {
        .ec-filter-button {
          color: var(--v-primary-base);
          
          .v-icon {
            color: var(--v-primary-base);
          }
        }
        
        .v-expansion-panel-header__icon .v-icon {
          color: var(--v-primary-base);
        }
      }
      
      .v-expansion-panel-content::v-deep {
        border-top: 1px solid var(--v-primary-base);
      }
    }
  }
}
</style>

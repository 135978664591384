<template>
  <ec-row class="align-center justify-center ec-width-1200">
    <ec-col cols="12">
      <div v-for="(group, index) in orderedGroups" :key="index">
        <ec-app-store-browser-group :group="group" class="ec-mb-4"/>
      </div>
    </ec-col>
  </ec-row>
</template>

<script>

import EcAppStoreBrowserGroup from './EcAppStoreBrowserGroup.vue'
import {GroupsArrayModel} from '@/models/GroupsArrayModel'

export default {
  
  components: {EcAppStoreBrowserGroup},
  
  computed: {
    orderedGroups() {
      let groupsArray = new GroupsArrayModel(this.$store.getters.translatedGroupsData)
      return groupsArray.groups.sort((a, b) => a.order > b.order ? 1 : -1)
    }
  }
  
}

</script>

<style lang="scss">

.ec-width-1200 {
  //max-width: 1200px;
  margin: 0 auto !important;
}

</style>

<template>
  <div>
      <a v-if="!full" class="ec-ml-2 ec-text-smaller"
         :href="'https://effectconnect.com/' + $store.getters.languageGetter"
         target="_blank">{{ $store.getters.getTranslation('app_detail_ec_no_client_yet_button_text') }}</a>
      <a class="ec-mt-2" v-else :href="'https://effectconnect.com/' + $store.getters.languageGetter"
         target="_blank">{{ $store.getters.getTranslation('app_detail_ec_no_client_yet_button_text') }}</a>
    </div>
</template>

<script>
export default {
  
  props: {
    full: {
      required: false,
      default: false,
      type: Boolean
    }
  }
  
}

</script>

<style lang="scss">

.ec-text-smaller {
  color: white !important;
  text-decoration: none !important;
}

</style>

import {AbstractModel} from './AbstractModel'
import {GroupModel} from './GroupModel'

export class GroupsArrayModel extends AbstractModel {

    groups

    constructor(data = {}) {
        super()
        this.setGroups(data)
    }

    setGroups(groupsData) {
        let groups = []
        if (Object.prototype.hasOwnProperty.call(groupsData, 'data')) {
            groupsData.data.forEach(group => {
                groups.push(new GroupModel(group))
            })
        }
        this.groups = groups
    }

}

<template>
  <div>
    <v-carousel v-if="appStoreSlidesGetter.slides.length > 0"
                v-model="carousel"
                :height="$vuetify.breakpoint.smAndUp ? 435 : ''"
                :hide-delimiters="appStoreSlidesGetter.slides.length < 2"
                :show-arrows="appStoreSlidesGetter.slides.length > 1"
                hide-delimiter-background
                delimiter-icon="icon-ec-active"
                next-icon="icon-ec-arrow-right"
                prev-icon="icon-ec-arrow-left"
                :cycle="true"
                interval="10000"
                class="ec-carousel">
      <template v-slot:prev="{on, attrs}">
        <ec-btn v-bind="attrs" v-on="on" outlined prepend-icon="icon-ec-arrow-left"/>
      </template>
      <template v-slot:next="{on, attrs}">
        <ec-btn outlined v-bind="attrs" v-on="on" prepend-icon="icon-ec-arrow-right"/>
      </template>
      <v-carousel-item :style="bannerStyling(slide)" v-for="slide in appStoreSlidesGetter.slides" :key="slide.order">
        <div class="d-flex justify-center ec-app-store-browser-banner">
          <div class="d-flex ec-app-store-browser-banner-text-area"></div>
          <div class="ec-app-store-browser-banner-image-area flex-grow-1" :style="'background-size: cover !important; background-position: center !important; background-repeat: no-repeat !important;' + ' ' + backgroundImageStyling(slide)"></div>
        </div>
        <div class="d-flex justify-center ec-app-store-browser-banner-text-overlay">
          <div class=" d-flex align-center ec-app-store-browser-banner-text-overlay-box">
            <div class="ec-app-store-browser-banner-text-area">
              <div class="ec-banner-title">{{ slide.title }}</div>
              <div v-html="truncateEndText(slide.description)" class="ec-my-4 ec-banner-subtitle"></div>
              <ec-btn color="white" dark outlined append-icon="icon-ec-arrow-right" @click="ctaAction(slide)">
                {{ slide.action.title }}
              </ec-btn>
            </div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>

import {SlidesArrayModel} from '@/models/SlidesArrayModel'

export default {
  data() {
    return {
      carousel: 0,
      maxLength: 250 // Max length before truncating text
    }
  },
  
  computed: {
    appStoreSlidesGetter() {
      return new SlidesArrayModel(this.$store.getters.translatedSlidesData)
    },
  },
  
  methods: {

    bannerStyling(slide) {
      return 'background: linear-gradient(to ' + slide.gradient.whereTo + ', ' + slide.gradient.beginColor + ', ' + slide.gradient.endColor + ');'
    },
  
    backgroundImageStyling(slide) {
      if (slide.imageUrl) {
        return 'background-image: url(\'/images/banner/' + slide.imageUrl + '\') !important;'
      } else {
        return ''
      }
    },
    
    image(slide) {
      return '/images/banner/' + slide.imageUrl
    },
    
    truncateEndText(text) {
      if (text.length > this.maxLength) {
        return text.slice(0, this.maxLength) + '...'
      } else {
        return text
      }
    },
    
    ctaAction(slide) {
      if (slide.action.type === 'external_link') {
        window.open(slide.action.value)
      } else if (slide.action.type === 'app') {
        let appName = this.$store.getters.getAppDetailField(slide.action.value, 'name')
        this.$router.push({name: 'EcExternalAppDetail', params: {id: slide.action.value, name: appName, formattedAppName: appName.toLowerCase().replace(/\s/g, '–').replace(',', '')}})
      }
    }
  }
  
}

</script>

<style lang="scss" scoped>

::v-deep {
  
  .v-window__prev, .v-window__next {
    border-radius: $ec-default-border-radius;
    background-color: rgba(0, 0, 0, 0.15);
    
    .v-icon {
      font-size: 18px !important;
    }
  }
  
  .v-carousel__controls {
    .v-carousel__controls__item {
      margin: $ec-spacing-1;
      height: 10px;
      width: 10px;
      color: white;
      
      &.v-item--active {
        opacity: 1 !important;
        background: white;
      }
    }
  }
}

//.ec-carousel::v-deep {
//  border-radius: $ec-default-border-radius;
//}

.ec-app-store-browser-banner {
  height: 100%;
  
  .ec-app-store-browser-banner-text-area::v-deep {
    height: inherit;
    color: white;
    width: 60%;
    align-items: center;
  }
  
  .ec-app-store-browser-banner-image-area {
    height: inherit;
    width: 40%;
    clip-path: ellipse(100% 120% at 105% 50%);
  }
}

.ec-app-store-browser-banner-text-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 435px;
  
  .ec-app-store-browser-banner-text-overlay-box {
    width: 1400px;
    padding-left: $ec-spacing-3;
    padding-right: $ec-spacing-3;
    
    .ec-app-store-browser-banner-text-area::v-deep {
      height: inherit;
      color: white;
      width: 60%;
      align-items: center;
      
      .ec-banner-title {
        font-size: 2.5em;
        font-weight: $ec-heading-font-weight-bold !important;
        font-family: $ec-heading-font-family;
      }
      
      .ec-banner-subtitle {
        font-size: 1.3em;
        font-family: $ec-heading-font-family;
      }
      
      button {
        background-color: white;
        
        .v-btn__content, .v-icon {
          color: #2D2E83 !important;
        }
        
        //&:hover {
        //  background-color: #2D2E83 !important;
        //
        //  .v-btn__content, .v-icon {
        //    color: white !important;
        //  }
        //}
      }
    }
  }
  
  @media only screen and (max-width: 1180px) {
    .ec-app-store-browser-banner-text-overlay-box {
      padding-left: $ec-spacing-5 !important;
      padding-right: $ec-spacing-5 !important;
    }
  }
}

</style>

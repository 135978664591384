<script>
import {VExpansionPanels} from 'vuetify/lib'

export default {
  extends: VExpansionPanels,

  props: {
    error: {
      type: Boolean
    }
  },

  computed: {
    classes () {
      return {
        ...VExpansionPanels.options.computed.classes.call(this),
        'v-expansion-panels--disabled': this.disabled,
        'v-expansion-panels--error': this.error
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-expansion-panels {
  border: 1px solid var(--v-ec_border-base);
  border-radius: 5px;

  .v-expansion-panel::v-deep {
    .v-expansion-panel-header {
      font-size: 14px;
      min-height: 50px;
      padding: 0 20px;

      &:focus::before {
        opacity: 0;
      }
    }

    &::after {
      border-color: var(--v-ec_border-base) !important;
    }

    &::before {
      box-shadow: none;
    }

    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding: 0 20px 20px 20px;
        width: 100%;
      }
    }
  }

  &--disabled {
    border: 1px solid var(--v-ec_border-lighten5);

    .v-expansion-panel::v-deep {
      &::after {
        border-color: var(--v-ec_border-lighten5) !important;
      }
    }
  }

  &--error {
    border: 1px solid var(--v-error-base);

    .v-expansion-panel::v-deep {
      &::after {
        border-color: var(--v-error-base) !important;
      }
    }
  }

}
</style>
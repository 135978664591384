<template>
  <div class="ec-splash d-flex flex-column align-center">
    <ec-img width="200" heigth="200" :src="require('../assets/images/splash/' + img + '.svg')" :alt="$store.getters.getAltText('no-content-found')"/>
    <div v-if="hasDefaultSlot" class="ec-splash-slot">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      required: true,
      type: String
    }
  },

  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    }
  },
}
</script>

<style lang="scss" scoped>
.ec-splash {
  text-align: center;
  padding: $ec-spacing-4;

  .ec-splash-slot {
    margin-top: $ec-spacing-3;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>

<script>
import {VCardTitle} from 'vuetify/lib'

export default {
  extends: VCardTitle,
  
  props: {
    secondary: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  render(createElement, context) {
    let classes = []
    classes.push('v-card__title')
    classes.push('ec-card__title')
    if (context.props.secondary) {
      classes.push('ec-card__title-secondary')
    }
    if (context.data.staticClass) {
      classes.push(context.data.staticClass)
    }
    context.data.staticClass = classes.join(' ')
    return createElement('h2', context.data, context.children)
  }
}
</script>

<style lang="scss" scoped>
.ec-card__title {
  font-family: $ec-heading-font-family;
  font-weight: $ec-heading-font-weight-semi-bold;
  line-height: $line-height-root;
  font-size: $ec-font-size-3;
  margin: 0 0 $ec-spacing-2 0;
  padding: 0;
  color: var(--v-accent-base);
  word-break: break-word;

  &-secondary {
    font-weight: $ec-heading-font-weight-bold;
    line-height: $ec-font-size-4;
    font-size: $ec-font-size-4;
    margin: 0 0 $ec-spacing-1 0;
    color: var(--v-ec_dark_blue-base);
  }
}
</style>

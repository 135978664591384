<script>
import {VBtn} from 'vuetify/lib'
import EcProgressCircular from '@/components/EcProgressCircular'
import EcIcon from '@/components/EcIcon'

export default {

  extends: VBtn,

  props: {
    color: {
      default: 'primary',
    },
    depressed: {
      default: true,
    },
    prependIcon: {
      default: '',
    },
    appendIcon: {
      default: '',
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {

    genContent () {
      return this.$createElement('span', {
        staticClass: this.getStaticClass(),
      }, [this.genPrependIcon(), this.$slots.default, this.genAppendIcon()])
    },

    genLoader () {
      let classes = ['v-btn__loader']
      if (this.$slots.loader !== undefined) {
        classes.push('ec-btn__loader-text')
      }
      return this.$createElement('span', {
        staticClass: classes.join(' '),
      }, [this.$slots.loader, this.$createElement(EcProgressCircular)])
    },

    genPrependIcon () {
      if (this.prependIcon !== '') {
        return this.$createElement(EcIcon, {
          staticClass: 'ec-prepend-icon',
          props: { left: true },
        }, this.prependIcon);
      }
      return null;
    },

    genAppendIcon () {
      if (this.appendIcon !== '') {
        return this.$createElement(EcIcon, {
          staticClass: 'ec-append-icon',
          props: { left: true },
        }, this.appendIcon);
      }
      return null;
    },

    getStaticClass () {
      let classes = ['v-btn__content'];
      if (this.$slots.default === undefined) {
        classes.push('ec-btn__content-empty')
      }
      if (this.$slots.loader !== undefined) {
        classes.push('ec-btn__content-loader-text')
      }
      return classes.join(' ')
    },

  },

  computed: {
    classes () {
      return {
        ...VBtn.options.computed.classes.call(this),
        'ec-no-border': this.noBorder,
        'ec-btn': true
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/overrides/VBtn.scss';
</style>

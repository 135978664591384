<script>
import { VCardSubtitle } from 'vuetify/lib'

export default {
  extends: VCardSubtitle,

  props: {
    secondary: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  render (createElement, context) {
    let classes = []
    classes.push('v-card__subtitle')
    classes.push('ec-card__subtitle')
    if (context.props.secondary) {
      classes.push('ec-card__subtitle-secondary')
    }
    if (context.data.staticClass) {
      classes.push(context.data.staticClass)
    }
    context.data.staticClass = classes.join(' ')
    return createElement('div', context.data, context.children)
  }
}
</script>

<style lang="scss" scoped>
.ec-card__subtitle {
  font-weight: $ec-body-font-weight-light;
  margin: 0 0 $ec-spacing-4 0;
  padding: 0;
  color: var(--v-text-lighten1);

  &-secondary {
    margin: 0 0 $ec-spacing-3 0;
  }
}
</style>
<template>
  <div
      :class=" $store.getters.getAppDetailField($route.params.id, 'usps').length > 0 ? 'ec-py-3 ec-app-store-border-bottom' : 'ec-pb-3 ec-app-store-border-bottom'">
    <div>
      <ec-h4>{{ $store.getters.getAppDetailField($route.params.id, 'facilitatorAnnouncement') }}</ec-h4>
    </div>
    <div>
        <ec-col class="col-sm-3 col-md-3 col-lg-3 col-xl-3 ec-ma-0 ec-pa-0" cols="3">
          <ec-img contain position="center center" class="ec-mb-2" :src="facilitatorLogo" :alt="$store.getters.getAltText(facilitatorName)" />
        </ec-col>
        <ec-h3>{{ facilitatorName }}</ec-h3>
        <ec-col class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ec-ma-0 ec-pa-0" cols="11">
          <div class="ec-mt-1" v-html="$store.getters.getAppDetailField($route.params.id, 'facilitator').description"/>
          <ec-link-href :href="$store.getters.getAppDetailField($route.params.id, 'facilitator').url" target="_blank"
                        inline
                        append-icon="icon-ec-link-external"
                        icon-color="primary">{{ $store.getters.getAppDetailField($route.params.id, 'facilitator').url }}
          </ec-link-href>
        </ec-col>
    </div>
  </div>
</template>

<script>

export default {
  
  computed: {
    facilitatorName() {
      return this.$store.getters.getAppDetailField(this.$route.params.id, 'facilitator').name
    },
    facilitatorLogo() {
      return '/images/facilitators/logos/' + this.$store.getters.getAppDetailField(this.$route.params.id, 'facilitator').logoUrl
    }
  }
  
}

</script>

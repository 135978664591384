<template>
  <v-app-bar :height="80">
    <div class="d-flex ec-width-1200 justify-space-between align-center">
      <router-link :to="{name: 'ExternalAppStore'}">
        <ec-img alt="effectconnect-logo"
                :contain="true" position="left" :src="require('../../../assets/images/logo.svg')" max-height="20"
                :max-width="$vuetify.breakpoint.smAndUp ? '300' : '150'"/>
      </router-link>
      
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" icon>
            <ec-img :contain="true" width="auto" :src="require('../../../assets/images/icon-globe.svg')"
                    max-height="16" alt="effectconnect-globe-icon"/>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :disabled="item.value === $store.getters.languageGetter"
                       active-class="active-language"
                       v-for="(item, index) in $store.getters.languagesGetter" :key="index" link
                       @click="setLanguage(item.value)">
            <v-list-item-title v-text="item.text"/>
          </v-list-item>
        </v-list>
      </v-menu>
    
    </div>
  </v-app-bar>
</template>

<script>


export default {
  
  created() {
    this.$store.commit('changeLanguage', this.$route.params.language)
    this.$watch(
        () => this.$route.params,
        (toParams) => {
          this.$store.commit('changeLanguage', toParams.language)
        }
    )
  },
  
  methods: {
    setLanguage(value) {
      this.$store.commit('changeLanguage', value)
      this.$router.push({name: this.$route.name, params: {language: value}})
    }
  }
  
}

</script>

<style lang="scss" scoped>

.v-sheet {
  box-shadow: none !important;
}

.ec-width-1200 {
  width: 1360px;
}

.active-language, .v-list-item--disabled {
  color: var(--v-ec_dark_text-base);
  background-color: var(--v-primary-base);
}

</style>

<script>
import { VTextField } from 'vuetify/lib'

export default {

  extends: VTextField,

  props: {

    // Overrides from VInput.ts
    hideDetails: {
      default: 'auto'
    },

    // Overrides from VTextField.ts
    outlined: {
      default: true
    },

    prependInnerIcon: {
      default: 'icon-ec-search' // TODO: make clickable
    },

    clearable: {
      default: true
    },

    clearIcon: {
      default: 'icon-ec-close-filled'
    }

  },

  computed: {

    showLabel() {
      return !this.isFocused && !this.isDirty
    },

    // TODO: is there another way to set default classes?
    classes () {
      return {
        ...VTextField.options.computed.classes.call(this),
        'ec-search-field' : true,
      }
    }

  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/overrides/VInput.scss';
.v-input::v-deep input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset; // Remove browser auto-complete styling
}

.ec-search-field  {
  margin-bottom: $ec-spacing-2;
}

</style>

<template>
  <div class="ec-app-store-border-bottom ec-pb-3"
       v-if="$store.getters.getAppDetailField($route.params.id, 'usps').length > 0">
    <v-list v-for="(usp, index) in $store.getters.getAppDetailField($route.params.id, 'usps')" :key="index"
            class="d-flex">
      <v-list-item class="ec-px-0">
        <v-list-item-icon class="ec-mr-2">
          <ec-icon color="primary">icon-ec-success</ec-icon>
        </v-list-item-icon>
        <v-list-item-content class="ec-my-0 ec-py-1">
          <v-list-item-title>{{ usp.value }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {}

</script>

<style lang="scss">

.v-list-item {
  .v-list-item__title {
    white-space: break-spaces;
    line-height: 1.5;
  }
}

</style>

<template>
  <span :class="classes">
    <a v-if="prependIcon" class="ec-link-prepend-icon" :href="href" :target="targetComputed">
      <ec-icon :color="iconColorComputed" :size="iconSize">{{ prependIcon }}</ec-icon>
    </a>
    <a class="ec-link-content" :href="href" :target="targetComputed">
      <slot/>
    </a>
    <a v-if="appendIconComputed" class="ec-link-append-icon" :href="href" :target="targetComputed">
      <ec-icon :color="iconColorComputed" :size="iconSize">{{ appendIconComputed }}</ec-icon>
    </a>
  </span>
</template>

<script>

import EcIcon from '@/components/EcIcon.vue'
export default {
  components: {EcIcon},
  
  props: {
    href: {
      required: true,
      type: String,
      default: ''
    },
    target: {
      required: false,
      type: String,
      default: '_self'
    },
    prependIcon: {
      required: false,
      type: String,
      default: ''
    },
    appendIcon: {
      required: false,
      type: String,
      default: ''
    },
    iconColor: {
      required: false,
      type: String,
      default: ''
    },
    iconSize: {
      required: false,
      type: Number,
      default: 10
    },
    inline: {
      required: false,
      type: Boolean,
      default: false
    },
    external: {
      required: false,
      type: Boolean,
      default: false
    },
    dark: {
      required: false,
      type: Boolean,
      default: false
    },
  },

  computed: {
    classes () {
      return {
        'ec-link': true,
        'ec-link-inline': this.inline,
        'ec-link-dark': this.dark,
      }
    },

    targetComputed () {
      if (this.external) {
        return '_blank'
      }
      return this.target
    },

    appendIconComputed () {
      if (this.external) {
        return 'icon-ec-link-external'
      }
      return this.appendIcon
    },

    iconColorComputed () {
      if (this.iconColor) {
        return this.iconColor
      } else if (this.dark) {
        return 'text-lighten5'
      }
      return 'primary'
    },
  }
}
</script>

<style lang="scss" scoped>
.ec-link {

  &:not(.ec-link-inline) {
    display: flex;
    white-space: nowrap;
  }

  a.ec-link-prepend-icon {
    margin-right: 10px;
    text-decoration: none;
  }

  a.ec-link-append-icon {
    margin-left: 5px;
    text-decoration: none;
  }

  a.ec-link-content {
    font-weight: 100;
    text-decoration: underline;
  }

  &.ec-link-dark a {
    color: var(--v-text-lighten5);
  }
}
</style>

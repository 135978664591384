<template>
  <ec-col xs="6" sm="6" md="6" lg="2" class="ec-app-store-border-right d-flex justify-center align-center">
    <div class="align-center">
      <ec-h4>{{ $store.getters.getTranslation('app_detail_category_title') }}</ec-h4>
      <ec-chip class="ec-appstore-tag-component" color="primary">
        {{ $store.getters.getAppDetailField($route.params.id, 'category') }}
      </ec-chip>
    </div>
  </ec-col>
</template>

<script>

export default {}

</script>

<style lang="scss" scoped>

.ec-appstore-tag-component {
  word-break: break-word;
}

.ec-h4 {
  text-align: center;
}

</style>

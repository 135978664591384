<template>
  <ec-col cols="12" class="col-sm-9 col-md-9 col-lg-6 col-xl-6 ec-app-store-border-right d-inline-flex align-center">
    
    <div class="d-flex flex-column">
      <div class="d-inline-flex">
        <h2>{{ $store.getters.getAppDetailField($route.params.id, 'name') }}</h2>
      </div>
      <div class="d-flex">
        <p class="ec-appstore-subtitle-field">{{ $store.getters.getAppDetailField($route.params.id, 'subtitle') }}</p>
      </div>
      <div class="d-flex">
        <div v-for="(countryObject, index) in $store.getters.getAppDetailField($route.params.id, 'countries')"
             :key="index">
          <ec-tooltip :text="getTooltipText(countryObject['value'])">
            <template v-slot:activator>
              <ec-img class="ec-mx-1"
                      :alt="$store.getters.getAltText(countryObject['key'] + '–flag')"
                      :src="require('../../../assets/images/flags/' + countryObject['key'].toLowerCase() + '.png')"
                      max-height="12" max-width="20"/>
            </template>
          </ec-tooltip>
        </div>
      </div>
    </div>
  </ec-col>
</template>

<script>


export default {
  methods: {
    getTooltipText(countryCode) {
      return countryCode.startsWith('translate.') ? this.$store.getters.getTranslation(countryCode) : countryCode
    }
  }
}

</script>

<style lang="scss" scoped>
h2 {
  font-family: $ec-heading-font-family;
  font-size: $ec-font-size-1;
  font-weight: $ec-heading-font-weight-bold;
  color: var(--v-text-base);
  margin: 0;
  padding: $ec-spacing-1 0; // Align with actions buttons to the right
}

p.ec-appstore-subtitle-field {
  word-break: break-word;
  text-align: left;
}
</style>

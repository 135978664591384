<template>
  <ec-card>
    <ec-card-title v-if="!hideContentTitle">
      {{ tab.title }}
    </ec-card-title>
    <ec-card-subtitle v-if="tab.subtitle">
      {{ tab.subtitle }}
    </ec-card-subtitle>
    <ec-card-text>
      <div v-if="tab.text"> {{ tab.text }}</div>
      <component v-bind:is="tab.component" v-if="tab.component" :tab="tab" v-bind="parameters" :custom-key="customKey"
                 @refresh="refresh"/>
    </ec-card-text>
  </ec-card>
</template>

<script>

import EcCard from './EcCard.vue'
import EcCardTitle from './EcCardTitle.vue'
import EcCardSubtitle from './EcCardSubtitle.vue'
import EcCardText from './EcCardText.vue'

export default {
  
  components: {EcCard, EcCardTitle, EcCardSubtitle, EcCardText},
  
  props: {
    tab: {
      required: true,
      type: Object
    },
    parameters: {
      required: false,
      type: Object
    },
    hideContentTitle: {
      required: false,
      type: Boolean,
      default: false,
    },
    // customKey is increased every time the tab is changed, can be used within tab component to watch tab changes (and then refresh content)
    customKey: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

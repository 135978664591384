<template>
  <ec-page-wrapper>
    
    <ec-header/>
    
    <slot name="content"/>
    
    <ec-footer/>
  
  </ec-page-wrapper>
</template>

<script>

import EcHeader from '@/pages/components/static/EcHeader.vue'
import EcFooter from '@/pages/components/static/EcFooter.vue'
export default {
  components: {EcFooter, EcHeader}
}

</script>

import {AbstractModel} from './AbstractModel'
import {CardInformationModel} from "./CardInformationModel"
import {AppPropertyTranslationModel} from "./AppPropertyTranslationModel"

export class CardModel extends AbstractModel {

    id
    name
    countryCodes
    type
    status
    beta
    logoUrl
    category
    information
    isAvailable

    constructor(data = {}) {
        super()
        this.setProperty('id', data.id, 0)
        this.setProperty('name', data.name, '')
        this.setProperty('countryCodes', data.countryCodes, [])
        this.setProperty('type', data.type, '')
        this.setProperty('status', data.status, '')
        this.setProperty('beta', data.beta, 0)
        this.setProperty('logoUrl', data.logoUrl, '')
        this.setProperty('category', new AppPropertyTranslationModel(data.category.information ?? {}))
        this.setProperty('information', new CardInformationModel(data.information ?? {}))
        this.setProperty('isAvailable', data.isAvailable, false)
    }

    isBeta() {
        return this.beta === 1
    }

    isAvailableInLegacySubscription() {
        return this.isAvailable
    }

}

<template>
  <ec-row class="align-center justify-center ec-width-1200">
    <ec-col cols="12" class="ec-mt-2">
      <ec-card>
        
        <ec-card-title>
          {{ $store.getters.getTranslation('overview_search_card_title') }}
        </ec-card-title>
        <ec-card-subtitle>
          {{ $store.getters.getTranslation('overview_search_card_subtitle') }}
        </ec-card-subtitle>
        
        <ec-search-field :placeholder="$store.getters.getTranslation('overview_search_card_placeholder')" v-model="searchQueryField"/>
        
        <ec-filter-panel />
      
      </ec-card>
    </ec-col>
  </ec-row>
</template>

<script>

import EcSearchField from '@/components/EcSearchField.vue'
import EcFilterPanel from '@/components/EcFilterPanel.vue'

export default {
  components: {EcFilterPanel, EcSearchField},
  
  computed: {
    searchQueryField: {
      get() {
        return this.$store.getters.searchFieldGetter
      },
      set(newValue) {
        this.$store.commit('search', newValue)
      }
    }
  }
  
}

</script>

<style lang="scss">

.ec-width-1200 {
  //max-width: 1200px;
  margin: 0 auto !important;
}

</style>

<script>
import { VCardText } from 'vuetify/lib'

export default {
  extends: VCardText,
}
</script>

<style lang="scss" scoped>
.v-card__text {
  padding: 0;
}
</style>
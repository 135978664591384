<script>
import { VSelect } from 'vuetify/lib';

export default {
  
  extends: VSelect,
  
  props: {
    
    // Overrides from VSelect.ts
    menuProps: {
      default: () => {
        return { top: false, offsetY: true };
      },
    },
    outlined: {
      default: true,
    },
    
    // Overrides from VInput.ts
    hideDetails: {
      default: 'auto',
    },
    
    // Custom
    ecSmall: {
      required: false,
      type: Boolean,
      default: false,
    },
    
  },
  
  computed: {
    
    showLabel() {
      return !this.isFocused && !this.isDirty;
    },
    
    // TODO: is there another way to set default classes?
    classes () {
      return {
        ...VSelect.options.computed.classes.call(this),
        'ec-select-single' : true,
        'ec-small'         : this.ecSmall,
        'rounded-5'        : true,
      };
    },
    
  },
  
  methods: {
    setValue (value) {
      VSelect.options.methods.setValue.call(this, value)
      this.$emit('set-value', value)
    }
  }
  
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/overrides/VInput.scss';
@import '@/assets/scss/overrides/VMenu.scss';
@import '@/assets/scss/overrides/VList.scss';

</style>

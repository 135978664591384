<template>
  <ec-row class="align-center justify-center ec-width-1200">
    <ec-col cols="12">
      <ec-card class="ec-mb-4">
        
        <ec-card-title>
          {{ $store.getters.getTranslation('overview_search_results_card_title') }}
        </ec-card-title>
        
        <ec-card-subtitle>
          {{ $store.getters.getTranslation('overview_search_results_card_subtitle') }}
        </ec-card-subtitle>
        
        <ec-app-store-browser-group-component :group="filteredApps"/>
        
        <div v-if="!filteredApps.hasRelatedApps()">
          <ec-splash-image img="no-content">
            <ec-card-title>{{ $store.getters.getTranslation('overview_search_splash_title') }}</ec-card-title>
            <ec-card-subtitle>{{ $store.getters.getTranslation('overview_search_splash_subtitle') }}</ec-card-subtitle>
          </ec-splash-image>
        </div>
      
      </ec-card>
    </ec-col>
  </ec-row>
</template>

<script>

import EcAppStoreBrowserGroupComponent from '@/pages/components/EcAppStoreBrowserGroupComponent.vue'
import {SearchResultsModel} from '@/models/SearchResultsModel'

export default {
  components: {EcAppStoreBrowserGroupComponent},
  
  computed: {
    filteredApps() {
      let filteredApps = this.$store.getters.translatedAppsData.data
      if (this.$store.getters.appliedFiltersGetter.length > 0) {
        
        filteredApps = filteredApps.filter(app => {
          let filterCount = 0
          this.$store.getters.appliedFiltersGetter.forEach(filter => {
            let filterName = Object.keys(filter)[0]
            let filterValue = Object.values(filter)[0]
            if (filterName === 'countryFilter' && filterValue) {
              let countries = []
              Object.keys(app.countries).forEach(country => {
                countries.push(country.toLowerCase().trim())
              })
              // Country codes from https://gist.github.com/scottsweb/5300345 => added TR, UK, ... (check Internal API as well)
              let europeanCountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "TR", "UK"]
              let inEurope = europeanCountries.includes(filterValue.trim().toUpperCase())
              countries.includes(filterValue.trim().toLowerCase()) || countries.includes('world') || (countries.includes('eu') && inEurope) ? filterCount++ : null
            }
            if (filterName === 'categoryFilter' && filterValue) {
              app.category === filterValue ? filterCount++ : null
            }
            if (filterName === 'industryFilter' && filterValue) {
              app.industries.includes(filterValue) ? filterCount++ : null
            }
          })
          return filterCount === this.$store.getters.appliedFiltersGetter.length
        })
      }
      
      if (this.$store.getters.searchFieldGetter) {
        filteredApps = filteredApps.filter(app => {
          let tags = []
          app.tags.forEach(tag => {
            tags.push(tag.toLowerCase().trim())
          })
          return app.name.toLowerCase().trim().includes(this.$store.getters.searchFieldGetter.toLowerCase().trim()) ||
              tags.includes(this.$store.getters.searchFieldGetter.toLowerCase().trim())
        })
      }
      
      let cardModelApps = []
      // Parse apps to CardModel objects for display in GroupModel 'relatedApps'
      filteredApps.forEach(app => {
        let model = {
          'id': app.id,
          'name': app.name,
          'countryCodes': Object.keys(app.countries),
          'type': app.type,
          'status': app.status,
          'beta': app.isBeta,
          'logoUrl': app.logoUrl,
          'category': {information: {name: app.category, languageCode: ''}},
          'information': {subtitle: app.subtitle, languageCode: ''},
          'isAvailable': true
        }
        cardModelApps.push(model)
      })
      return new SearchResultsModel(cardModelApps.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    }
  }
}

</script>

<style lang="scss">

.ec-width-1200 {
  //max-width: 1200px;
  margin: 0 auto !important;
}

</style>

<template>
  <div class="ec-tabs-nested">
    <v-tabs v-model="active" height="48" hide-slider show-arrows @change="change">
      <v-tab
          v-for="(mainItem, mainIndex) in tabs"
          :key="mainIndex"
          :ripple="false"
          :transition="false"
          :to="useUrl ? mainUrl(mainItem) : ''"
          :class="'ec-tab' + (mainItem.dirty ? ' ec-tab-dirty' : '') + (mainItem.admin ? ' ec-tab-admin' : '')">
        <div class="ec-tab-title">
          {{ mainItem.title }}
        </div>
        <ec-icon v-if="mainItem.icon" :color="mainItem.icon_color">{{ mainItem.icon }}</ec-icon>
      </v-tab>
    </v-tabs>
    
    <v-tabs-items v-model="active">
      <v-tab-item :transition="false"
                  v-for="(mainItem, mainIndex) in tabs" :key="mainIndex">
        <ec-tab-content :tab="mainItem"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import EcIcon from '@/components/EcIcon.vue'
import EcTabContent from '@/components/EcTabContent.vue'

export default {
  
  components: {EcTabContent, EcIcon},

  props: {
    tabs: {
      required: true,
      type: Array,
    },
    baseUrl: {
      required: false,
      type: String,
      default: '/',
    },
    parameters: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
    hideContentTitle: {
      required: false,
      type: Boolean,
      default: false,
    },
    // Can be used to set the initial active tab in case no 'to' urls are used
    initialActive: {
      default: null,
    },
    useUrl: {
      required: false,
      type: Boolean,
      default: true,
    }
  },
  
  data() {
    // Set initial value for active tabs to make sure that only the active tab is loaded (when called by url)
    let nestedActive = {}, active = this.initialActive
    this.tabs.forEach(tab => {
      nestedActive[this.mainUrl(tab)] = null
    })
  
    if (Object.prototype.hasOwnProperty.call(this.parameters, 'activeParent')) {
      active = this.useUrl ? this.baseUrl + '/' + this.parameters.activeParent : this.parameters.activeParent
      if (Object.prototype.hasOwnProperty.call(this.parameters, 'activeChild')) {
        nestedActive[active] = this.baseUrl + '/' + this.parameters.activeParent + '/' + this.parameters.activeChild
      }
    }
    
    let keys = {}
    this.tabs.forEach(mainItem => {
      keys[this.mainUrl(mainItem)] = 0
      if (this.hasSubTabs(mainItem)) {
        mainItem.tabs.forEach(subItem => {
          keys[this.subUrl(mainItem, subItem)] = 0
        })
      }
    })
    
    return {
      keys: keys,
      active: active,
      nestedActive: nestedActive,
    }
  },
  
  methods: {
    hasSubTabs(item) {
      if (!Object.prototype.hasOwnProperty.call(item, 'tabs')) {
        return false
      }
      return Array.isArray(item.tabs) && item.tabs.length > 0
    },
    refresh() {
      this.$emit('refresh')
    },
    change(param) {
      let key = param
      // Param is sub tab or is main tab that has sub tab? Then refresh sub tab, otherwise refresh main tab.
      if (Object.prototype.hasOwnProperty.call(this.nestedActive, param) && this.nestedActive[param] !== null) {
        key = this.nestedActive[param]
      }
      this.keys[key]++
    },
    mainUrl(mainItem) {
      return mainItem.to ? this.baseUrl + '/' + mainItem.to : null
    },
    subUrl(mainItem, subItem) {
      return subItem.to ? this.baseUrl + '/' + mainItem.to + '/' + subItem.to : null
    },
  }
}
</script>

<style lang="scss" scoped>
.ec-tabs-nested {
  
  padding-top: 2px;
  border-radius: $ec-default-border-radius;
  background: var(--v-ec_background_element-base);
  position: relative;
  
  // Main AND sub tabs
  .v-tabs::v-deep .v-tabs-bar {
    
    background: transparent;
    padding: 0;
    
    &:not(.v-slide-group--is-overflowing) .v-slide-group__wrapper {
      padding: 0 $ec-spacing-3;
    }
    
    &--show-arrows {
      .v-slide-group__prev,
      .v-slide-group__next {
        min-width: $ec-spacing-4;
        flex-basis: $ec-spacing-4;
      }
    }
    
    .v-slide-group__prev:not(.v-slide-group__prev--disabled):after,
    .v-slide-group__next:not(.v-slide-group__next--disabled):after {
      content: "";
      position: absolute;
      top: 9px;
      bottom: 9px;
      left: 0;
      right: 0;
      width: 40px;
      z-index: 1;
      pointer-events: none;
    }
    
    .v-slide-group__prev:not(.v-slide-group__prev--disabled):after {
      right: unset;
      border-right: 1px solid #ECECEC;
    }
    
    .v-slide-group__next:not(.v-slide-group__next--disabled):after {
      left: unset;
      border-left: 1px solid #ECECEC;
    }
    
    .v-slide-group__wrapper {
      .v-tab {
        letter-spacing: 0;
        font-weight: normal;
        text-transform: none;
        text-decoration: none;
        padding: 0 15px;
        color: var(--v-text-base);
        
        &:not(.v-tab--active) {
          color: var(--v-text-lighten1);
        }
        
        // Separator left of tabs
        &::after {
          border-left: 1px solid #ECECEC;
          content: "";
          position: absolute;
          left: 0;
          height: 30px;
        }
        
        // Separator right of last tab
        &:last-child::after {
          border-right: 1px solid #ECECEC;
          content: "";
          position: absolute;
          right: 0;
          height: 30px;
        }
        
        .v-icon {
          margin-left: $ec-spacing-2;
        }
        
        &.ec-tab-dirty .ec-tab-title {
          font-style: italic;
          
          &::after {
            content: "*";
          }
        }
        
        &.ec-tab-admin .ec-tab-title::before {
          font-style: normal;
          content: "ADMIN";
          color: #FFFFFF;
          background-color: var(--v-warning-base);
          border-radius: $ec-default-border-radius;
          pointer-events: none;
          position: absolute;
          right: 2px;
          top: 2px;
          z-index: 1;
          font-family: $ec-body-font-family;
          font-weight: $ec-body-font-weight-regular;
          font-size: 8px;
          line-height: 8px;
          padding: 2px 3px;
        }
      }
    }
  }
  
  // Main tabs
  & > .v-tabs::v-deep .v-tabs-bar {
    
    z-index: 1;
    
    .v-slide-group__prev:not(.v-slide-group__prev--disabled):after {
      border-color: #C4C9DB;
    }
    
    .v-slide-group__next:not(.v-slide-group__next--disabled):after {
      border-color: #C4C9DB;
    }
    
    .v-slide-group__wrapper {
      .v-tab {
        transition: none !important; // EC override - a tags have transition by default in EC framework
        
        &::before {
          border-top-right-radius: $ec-form-field-border-radius;
          border-top-left-radius: $ec-form-field-border-radius;
        }
        
        &--active {
          background-color: #FFFFFF;
          border-top-right-radius: $ec-form-field-border-radius;
          border-top-left-radius: $ec-form-field-border-radius;
          z-index: 1;
        }
        
        // No hover effect on active/focus tab
        &--active::before {
          display: none;
        }
        
        // Separator left of tabs
        &::after {
          border-color: #C4C9DB;
        }
        
        // Separator right of last tab
        &:last-child::after {
          border-color: #C4C9DB;
        }
        
        // No separator for active tab
        &--active:last-child::after,
        &--active::after {
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
        }
        
        // No separator after active tab element
        &--active + .v-tab::after {
          border-left: 1px solid transparent;
        }
        
        // No separator for hover/focus tab
        &:focus::after,
        &:hover::after {
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
        }
        
        // No separator after hover/focus tab element
        &:focus + .v-tab::after,
        &:hover + .v-tab::after {
          border-left: 1px solid transparent;
        }
      }
    }
  }
  
  // Sub tab
  .ec-sub-tabs .v-tabs::v-deep .v-tabs-bar {
    border-bottom: 1px solid #ECECEC;
    
    .v-slide-group__wrapper {
      .v-tab {
        // Hover/focus tab
        &:focus:before,
        &:hover:before {
          color: #BFC4D8;
        }
        
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        
        &[aria-selected="true"] {
          border-bottom: 2px solid var(--v-primary-base);
        }
      }
    }
  }
  
  // Main and sub tab content
  .v-tabs-items {
    border-radius: $ec-default-border-radius;
    
    .ec-card {
      box-shadow: none;
    }
  }
  
  // Only main tab content
  & > .v-tabs-items {
    overflow: visible;
    position: relative;
    
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      box-shadow: $ec-box-shadow;
      border-radius: $ec-default-border-radius;
    }
  }
}
</style>

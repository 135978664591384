<script>
import { VIcon } from 'vuetify/lib'
export default {
  extends: VIcon
}
</script>
<style lang="scss" scoped>
.v-icon {
  font-size: 18px;
  &.icon-ec-drag {
    color: var(--v-ec_icon-lighten3);
  }
  &.icon-ec-success-outlined {
    color: var(--v-success-base);
  }
  &.icon-ec-error-outlined,
  &.icon-ec-delete {
    color: var(--v-error-base);
  }
}
</style>

<template>
  <ec-app>
    <router-view/>
  </ec-app>
</template>

<script>

export default {
  name: 'App'
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/overrides/VApp.scss';
@import '@/assets/scss/transitions.scss';
</style>

import {AbstractModel} from './AbstractModel'
export class AppModuleModel extends AbstractModel {

    type
    subtype
    internalUrl

    constructor(data = {}) {
        super()
        this.setProperty('type', data.type, '')
        this.setProperty('subtype', data.subtype, '')
        this.setProperty('internalUrl', data.internalUrl, '')
    }

}

<template>
  <span :class="classes">
    <ec-btn @click="$emit('click', $event)" v-if="prependIcon && prependIconIsButton" :color="iconColor" :prependIcon="prependIcon" outlined/>
    <ec-icon @click="$emit('click', $event)" v-if="prependIcon && !prependIconIsButton" :color="iconColor">{{ prependIcon }}</ec-icon>
    <a @click="$emit('click', $event)"><slot/></a>
  </span>
</template>

<script>

import EcBtn from '@/components/EcBtn.vue'
import EcIcon from '@/components/EcIcon.vue'
export default {
  components: {EcIcon, EcBtn},
  props: {
    prependIcon: {
      required: false,
      type: String,
      default: ''
    },
    iconColor: {
      required: false,
      type: String,
      default: 'primary'
    },
    prependIconIsButton: {
      required: false,
      type: Boolean,
      default: false
    },
    inline: {
      required: false,
      type: Boolean,
      default: false
    },
  },

  computed: {
    classes () {
      return {
        'ec-link': true,
        'ec-link-inline': this.inline,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ec-link {

  &:not(.ec-link-inline) {
    display: flex;
    align-items: center;
  }

  .v-btn,
  .v-icon {
    margin-right: 10px;
  }

  a {
    font-weight: 100;
    text-decoration: underline;
  }
}
</style>

<template>
  <h4 class="ec-h4"><slot/></h4>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.ec-h4 {
  font-family: $ec-heading-font-family;
  font-weight: $ec-heading-font-weight-semi-bold;
  line-height: $ec-font-size-4;
  font-size: $ec-font-size-4;
  margin: 0 0 $ec-spacing-3 0;
  color: var(--v-ec_dark_blue-base);
}
</style>

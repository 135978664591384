import {AbstractModel} from './AbstractModel'
import {GroupInformationModel} from './GroupInformationModel'
import {CardModel} from './CardModel'

export class GroupModel extends AbstractModel {

    id
    order
    active
    numberOfFeaturedApps
    information
    totalCount
    type
    relatedApps
    relatedLegacyChannels
    appIds

    constructor(data = {}) {
        super()
        this.setProperty('id', data.id, 0)
        this.setProperty('order', data.order, 0)
        this.setProperty('active', data.active, 0)
        this.setProperty('information', new GroupInformationModel(data.information ?? {}))
        this.setProperty('totalCount', data.totalCount, 0)
        this.setProperty('type', data.type, '')
        this.setProperty('numberOfFeaturedApps', data.numberOfFeaturedApps, 0)
        this.setProperty('appIds', data.appIds, [])
        this.setRelatedApps(data.relatedApps)
    }

    setRelatedApps(relatedAppsData) {
        let relatedApps = []
        let relatedLegacyChannels = []
        if (Object.prototype.hasOwnProperty.call(relatedAppsData, 'data') && relatedAppsData) {
            relatedAppsData.data.forEach((app) => {
                if (Object.prototype.hasOwnProperty.call(app, 'type')) {
                    relatedApps.push(new CardModel(app))
                } else {
                    // legacy
                }
            })
        }
        this.relatedApps = relatedApps
        this.relatedLegacyChannels = relatedLegacyChannels
    }

    hasRelatedApps() {
        return this.relatedApps.length + this.relatedLegacyChannels.length > 0
    }

    isMarketplacesGroup() {
        return this.type === 'marketplaces'
    }

}

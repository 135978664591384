<script>
import {VRow} from 'vuetify/lib'

export default {
  extends: VRow,
}
</script>

<style lang="scss" scoped>

.row {
  margin: -40px -20px 0 -20px;
  padding: 0;
  max-width: 1400px;
}

</style>

<script>
import {VApp} from "vuetify/lib"

export default {
  extends: VApp
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/overrides/VApp.scss';
@import '@/assets/scss/transitions.scss';
</style>

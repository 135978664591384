import {AbstractModel} from './AbstractModel'
export class AppFacilitatorModel extends AbstractModel {
    name
    description
    url
    logoUrl
    constructor(data = {}) {
        super()
        this.setProperty('name', data.name, '')
        this.setProperty('description', data.description, '')
        this.setProperty('url', data.url, '')
        this.setProperty('logoUrl', data.logoUrl, '')
    }

}

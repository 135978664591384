import {AbstractModel} from './AbstractModel'
export class AppMarketplaceModel extends AbstractModel {

    type
    subtype
    alias
    version
    internalUrl

    constructor(data = {}) {
        super()
        this.setProperty('type', data.type, '')
        this.setProperty('subtype', data.subtype, '')
        this.setProperty('alias', data.alias, '')
        this.setProperty('version', data.version, '')
        this.setProperty('internalUrl', data.internalUrl, '')
    }

}

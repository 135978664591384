import {AbstractModel} from './AbstractModel'
import {SlideModel} from './SlideModel'

export class SlidesArrayModel extends AbstractModel {

    slides

    constructor(data = {}) {
        super()
        this.setSlides(data)
    }

    setSlides(slidesData) {
        let slides = []
        if (Object.prototype.hasOwnProperty.call(slidesData, 'data')) {
            slidesData.data.forEach(slide => {
                slides.push(new SlideModel(slide))
            })
        }
        this.slides = slides
    }

}

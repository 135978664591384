<template>
  <div>
    
    <div class="ec-carousel-container">
      <div class="ec-carousel-container-inner">
        <ec-icon v-show="hideLeftArrow" class="ec-carousel-arrow-left" size="24" @click="slideLeft()">
          icon-ec-arrow-left
        </ec-icon>
        <ec-icon v-show="hideRightArrow" class="ec-carousel-arrow-right ec-ml-2" size="24" @click="slideRight()">
          icon-ec-arrow-right
        </ec-icon>
        <div class="ec-moving-carousel-container"
             :style="'position: absolute; width:100%; top:0; left:' + position + 'px;'">
          <div class="ec-carousel d-flex" ref="carouselBox">
            <div class="ec-carousel-placeholder ec-mr-2" v-for="(image, index) in images" :key="index">
              <div :style="absolutePositionStyling(image)" class="ec-carousel-placeholder-image"
                   @click.stop="showImageLightbox(index)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <v-dialog
        :scrollable="false"
        v-model="lightbox"
        max-width="1200"
        class="ec-lightbox"
        v-on:keydown.left="setPreviousImage()"
        v-on:keydown.right="setNextImage()"
        transition="scale-transition">
      <div class="ec-lightbox-content">
        <div v-if="images.length > 1">
          <ec-icon class="ec-lightbox-arrow-left" size="24" @click="setPreviousImage()">icon-ec-arrow-left</ec-icon>
          <ec-icon class="ec-lightbox-arrow-right" size="24" @click="setNextImage()">icon-ec-arrow-right</ec-icon>
        </div>
        <ec-icon v-if="$vuetify.breakpoint.smAndUp" class="ec-lightbox-close" size="24" @click="lightbox = !lightbox">
          icon-ec-close
        </ec-icon>
        <ec-img contain :src="prepend + lightBoxImage" :alt="$store.getters.getAltText($store.getters.getAppDetailField($route.params.id, 'name') + '–media')"/>
      </div>
    </v-dialog>
  
  </div>
</template>

<script>

export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    prepend: {
      type: String,
      required: true
    },
    placeholderSize: {
      type: String,
      required: false,
      default: '200'
    },
    lightboxActive: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  
  data() {
    return {
      lightbox: false,
      maxImages: 5,
      lightBoxImage: null,
      position: 0,
      isMounted: false
    }
  },
  
  mounted() {
    this.isMounted = true
  },
  
  computed: {
    hideLeftArrow() {
      return this.position < 0
    },
    hideRightArrow() {
      if (this.isMounted) {
        return this.position * -1 < (((this.images.length * 250) + ((this.images.length - 1) * 20) - this.$refs.carouselBox.clientWidth))
      } else {
        return true
      }
    }
  },
  
  methods: {
    showImageLightbox(index) {
      this.lightbox = !this.lightbox
      this.lightBoxImage = this.images[index]
    },
    
    slideLeft() {
      if (this.hideLeftArrow) {
        this.position = this.position + 260 // 250 + (20 / 2)
      }
    },
    
    slideRight() {
      if (this.hideRightArrow) {
        this.position = this.position - 260 // 250 + (20 / 2)
      }
    },
    
    setNextImage() {
      if (this.images.indexOf(this.lightBoxImage) !== this.images.length - 1) {
        this.lightBoxImage = this.images[this.images.indexOf(this.lightBoxImage) + 1]
      } else {
        this.lightBoxImage = this.images[0]
      }
    },
    
    setPreviousImage() {
      if (this.images.indexOf(this.lightBoxImage) !== 0) {
        this.lightBoxImage = this.images[this.images.indexOf(this.lightBoxImage) - 1]
      } else {
        this.lightBoxImage = this.images[this.images.length - 1]
      }
    },
    
    absolutePositionStyling(image) {
      return 'background-image: url(' + this.prepend + image + '); background-size: cover; background-repeat: no-repeat; background-position: center;'
    }
  }
}

</script>

<style lang="scss" scoped>

.ec-carousel-container {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  background-color: #DEE0EA;
}

.ec-carousel-container-inner {
  width: 100%;
  overflow: hidden;
}

.ec-carousel-arrow-left, .ec-carousel-arrow-right {
  position: absolute;
  top: calc(50% - 12px); // Icon size / 2
  z-index: 2;
  transition: all 300ms;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.15);
  padding: $ec-spacing-1;
  border-radius: 90px;
}

.ec-carousel-arrow-left, {
  left: 20px;
}

.ec-carousel-arrow-right {
  right: 20px;
}

.ec-moving-carousel-container, .ec-lightbox-image img, {
  transition: all 300ms;
}

.ec-carousel::v-deep {
  background-color: #DEE0EA;
  padding: 20px 0 20px 20px;
  
  .ec-carousel-placeholder, .ec-carousel-placeholder-image {
    box-shadow: $ec-box-shadow;
    border-radius: $ec-default-border-radius;
    background-color: white;
    cursor: pointer;
  }
  
  .ec-carousel-placeholder-image {
    height: 140px;
    width: 250px;
  }
  
}

.v-dialog__content {
  
  @media screen and (min-width: 1180px) {
    .ec-lightbox-content {
      padding: $ec-spacing-5;
    }
  }
  
  .ec-lightbox-content {
    border-radius: $ec-default-border-radius;
    background-color: white;
    position: relative;
    
    .ec-lightbox-close {
      position: absolute;
      right: $ec-spacing-3;
      top: $ec-spacing-3;
      cursor: pointer;
    }
    
    .ec-lightbox-arrow-right {
      position: absolute;
      right: 20px;
      top: 50%;
    }
    
    .ec-lightbox-arrow-left {
      position: absolute;
      left: 20px;
      top: 50%;
    }
  }
}

</style>

// TODO: generate CSS vars out of these?

const accent = Object.freeze({
    dark10: '#CF0071',
    base: '#E6007E',
    light50: '#F27FBE',
    light20: '#FACCE5',
    light10: '#FBD9EC',
});

const primary = Object.freeze({
    dark10: '#1677D9',
    base: '#1885F2',
    light50: '#8BC2F8',
    light20: '#D1E7FC',
    light10: '#E7F3FE',
    light2: '#F8FBFF',
});

const success = Object.freeze({
    dark10: '#55A140',
    base: '#5FB347',
    light50: '#AFD9A3',
    light40: '#BFE1B5',
    light25: '#D7ECD1',
    light10: '#DFF0DA',
    light2: '#FAFDF9',
});

const error = Object.freeze({
    dark10: '#C43D4B',
    base: '#DA4453',
    light50: '#ECA1A9',
    light20: '#F8DADD',
    light10: '#F9E3E5',
    light2: '#FEF9FA',
});

const warning = Object.freeze({
    dark10: '#C4851F',
    base: '#DA9422',
    light50: '#ECC990',
    light25: '#F6E4C8',
    light10: '#F8EAD3',
});

const darkblue = Object.freeze({
    dark10: '#2D2E83', // TODO
    base: '#2D2E83',
    light50: '#9696C1',
    light20: '#D5D5E6',
    light10: '#D5D5E6', // TODO
});

const cyan = Object.freeze({
    dark10: '#49CCCC', // TODO
    base: '#49CCCC',
    light50: '#A4E5E5',
    light20: '#A4E5E5', // TODO
    light10: '#A4E5E5', // TODO
});

const yellow = Object.freeze({
    dark10: '#FFF028', // TODO
    base: '#FFF028',
    light50: '#FFF793',
    light20: '#FFF793', // TODO
    light10: '#FFF793', // TODO
});

const purple = Object.freeze({
    dark10: '#C761D0', // TODO
    base: '#C761D0',
    light50: '#E3B0E7',
    light20: '#E3B0E7', // TODO
    light10: '#E3B0E7', // TODO
});

const shades = Object.freeze({
    black: '#000000',
    darker: '#282828',
    dark: '#737373',
    base: '#CCCCCC',
    light: '#ECECEC',
    lighter: '#FCFCFC',
    white: '#FFFFFF',
});

const backgrounds = Object.freeze({
    table: '#F2F3F7',
    form: '#F8F9FB', // TODO: is this used?
    element: '#DEE0EA',
});

const charts = [
    primary.base,
    warning.base,
    error.base,
    success.base,
    accent.base,
    cyan.base,
    darkblue.base,
    yellow.base,
    purple.base,
    primary.light50,
    warning.light50,
    error.light50,
    success.light50,
    accent.light50,
    cyan.light50,
    darkblue.light50,
    yellow.light50,
    purple.light50,
];

export default Object.freeze({
    accent,
    primary,
    success,
    error,
    warning,
    darkblue,
    shades,
    charts,
    backgrounds
})

import {AbstractModel} from './AbstractModel'
import {SlideActionModel} from './SlideActionModel'

export class SlideModel extends AbstractModel {

    id
    gradient
    title
    description
    imageUrl
    order
    action

    constructor(data = {}) {
        super()
        this.setProperty('id', data.id, 0)
        this.setProperty('gradient', data.gradient, {})
        this.setProperty('title', data.title, '')
        this.setProperty('description', data.description, '')
        this.setProperty('imageUrl', data.imageUrl, '')
        this.setProperty('order', data.order, 0)
        this.setProperty('action', new SlideActionModel(data.action ?? {}))

    }

}

import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

export default {
     install(Vue) {
         //Path to 'components' from index in main.js of project where we will use it (path is important)
        const components = require.context('@/components/', true, /[A-Za-z0-9-_,\s]+\.vue/i);

        components.keys().forEach(fileName => {
            const config = components(fileName)
            const componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')))
            Vue.component(componentName, config.default || config)
        });
    }
}

import {AbstractModel} from './AbstractModel'
import {CardInformationModel} from './CardInformationModel'
import {AppPropertyTranslationModel} from "./AppPropertyTranslationModel"

export class CardFromAppModel extends AbstractModel {

    id
    name
    countryCodes
    type
    status
    beta
    logoUrl
    category
    information
    isAvailable

    constructor(data = {}) {
        super()
        this.setProperty('id', data.id, 0)
        this.setProperty('name', data.name, '')
        this.setCountries( data.countries)
        this.setProperty('type', data.type, '')
        this.setProperty('status', data.status, '')
        this.setProperty('beta', data.beta, 0)
        this.setProperty('logoUrl', data.logoUrl, '')
        this.setProperty('category', new AppPropertyTranslationModel({name: data.category} ?? {}))
        this.setProperty('information', new CardInformationModel({subtitle: data.subtitle} ?? {}))
        this.setProperty('isAvailable', data.isAvailable, false)
    }

    getId() {
        return this.id
    }

    getName() {
        return this.name
    }

    isBeta() {
        return this.beta === 1
    }

    isAvailableInLegacySubscription() {
        return this.isAvailable
    }

    setCountries(countriesData) {
        let countries = []
        if (Object.keys(countriesData).length > 0) {
            Object.keys(countriesData).forEach(country => {
                countries.push(country)
            })
        }
        this.countryCodes = countries
    }

}

<template>
  <div class="ec-pt-4" v-if="$store.getters.getAppDetailField($route.params.id, 'contactInformation')">
    <ec-h4>{{ $store.getters.getTranslation('app_detail_contact_information_title') }}</ec-h4>
    <div v-html="$store.getters.getAppDetailField($route.params.id, 'contactInformation')"></div>
  </div>
</template>

<script>

export default {}

</script>

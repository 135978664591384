<template>
  <ec-col xs="6" sm="6" md="6" lg="2" class="d-flex justify-center align-center">
    <div v-if="$store.getters.getAppDetailField($route.params.id, 'industries').length > 0" class="align-center">
      <ec-h4>{{ $store.getters.getTranslation('app_detail_industries_title') }}</ec-h4>
      <div class="d-flex justify-center"
           v-for="(industry, index) in $store.getters.getAppDetailField($route.params.id, 'industries')" :key="index">
        <ec-chip
            :class="$store.getters.getAppDetailField($route.params.id, 'industries').length > 1 ? 'ec-mr-1 ec-mb-1 ec-appstore-tag-component' : 'ec-appstore-tag-component'"
            color="primary">
          {{ industry }}
        </ec-chip>
      </div>
    </div>
  </ec-col>
</template>

<script>

export default {}

</script>

<style lang="scss" scoped>

.ec-appstore-tag-component {
  text-align: center;
}

.ec-h4 {
  text-align: center;
}

</style>

<template>
  <div v-if="tab.data.array.length > 0" class="ec-app-store-optional-tab">
    <v-list v-for="(item, index) in tab.data.array" :key="index" class="d-flex">
      <v-list-item>
        <v-list-item-icon class="ec-mr-2 ec-my-2">
          <ec-icon color="primary">icon-ec-arrow-right</ec-icon>
        </v-list-item-icon>
        <v-list-item-content class="ec-my-0 ec-py-1">
          <v-list-item-title>{{ item.value }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

import EcIcon from '@/components/EcIcon.vue'

export default {
  
  components: {EcIcon},
  
  props: {
    tab: {
      required: true,
      type: Object
    }
  }
  
}

</script>

<style lang="scss" scoped>

.ec-app-store-optional-tab::v-deep {
  
  p {
    font-weight: 400;
  }
  
}

</style>

<template>
  <ec-card v-if="group.relatedApps.length > 0">
    
    <ec-card-title>
      {{ group.information.name }}
    </ec-card-title>
    
    <ec-card-subtitle>
      <div v-html="group.information.description"></div>
    </ec-card-subtitle>
    
    <ec-app-store-browser-group-component :group="group"/>
    
    <ec-card-actions bottom class="ec-mt-4">
      <ec-btn :to="{name: 'EcExternalAppStoreBrowserGroupDetail', params: {id: group.id, name: group.information.name, formattedGroupName: group.information.name.toLowerCase().replace(/\s/g,'–').replace(',', '')}}" color="primary" exact append-icon="icon-ec-arrow-right">
        {{ $store.getters.translationWithParametersGetter('view_all_apps_in_group', $store.getters.languageGetter, [{text: 'count', value: allAppsCount}, {text:'type', value: group.information.name}]) }}
      </ec-btn>
    </ec-card-actions>
  
  </ec-card>
</template>

<script>

import EcAppStoreBrowserGroupComponent from './EcAppStoreBrowserGroupComponent.vue'
import {GroupModel} from '@/models/GroupModel'

export default {
  
  components: {EcAppStoreBrowserGroupComponent},
  
  props: {
    group: {
      type: GroupModel,
      required: true
    }
  },
  
  computed: {
    allAppsCount() {
      let apps = []
      this.group.appIds.forEach(appId => {
        let app = this.$store.getters.translatedAppsData.data.find(app => app.id === appId)
        if (app) {
          apps.push(appId)
        }
      })
      return apps.length
    },
  }
  
}

</script>

<style lang="scss" scoped>

.ec-app-store-browser-app-card::v-deep {
  padding: $ec-spacing-3;
  border-radius: $ec-default-border-radius;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  text-align: center;
  min-height: 100%;
}

</style>

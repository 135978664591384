import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import components from './plugins/components'
import router from './plugins/routes'
import store from './plugins/store'
import VueGtag from 'vue-gtag'

Vue.use(components)

Vue.use(VueGtag, {
  config: { id: "G-9RLTWQQ1LH" }
})

window.trackPage = () => {
   let _hsq = window._hsq
    if (!_hsq) {
        return
    }
    _hsq.push(['setPath', window.location.pathname])
    _hsq.push(['trackPageView'])
}

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="ec-tabs">
    
    <v-tabs v-model="tab" color="text" height="48" hide-slider show-arrows>
      <v-tab
          v-for="item in items"
          :key="item.value"
          :ripple="false"
          :to="useUrl ? baseUrl + item.value : ''">
        {{ item.title }}
      </v-tab>
    </v-tabs>
    
    <v-tabs-items v-model="tab">
      <v-tab-item
          v-for="item in items"
          :key="item.value"
          :transition="false"
          :value="item.value">
        <ec-card>
          <ec-card-title>{{ item.title }}</ec-card-title>
          <ec-card-subtitle v-if="item.subtitle">{{ item.subtitle }}</ec-card-subtitle>
          <ec-card-text>
            <div v-if="item.text"> {{ item.text }}</div>
            <component v-bind:is="item.component" v-if="item.component" v-bind="parameters"/>
          </ec-card-text>
        </ec-card>
      </v-tab-item>
    </v-tabs-items>
  
  </div>
</template>

<script>

export default {
  
  props: {
    items: {
      required: true
    },
    parameters: {
      required: false
    },
    baseUrl: {
      required: false,
      default: ''
    },
    useUrl: {
      required: false,
      default: true
    }
  },
  
  data: () => ({
    tab: null,
  }),
}
</script>

<style lang="scss" scoped>
.ec-tabs {
  
  padding-top: 2px;
  border-radius: $ec-default-border-radius;
  background: var(--v-ec_background_element-base);
  position: relative;
  
  .v-tabs::v-deep {
    
    .v-tabs-bar {
      
      background: transparent;
      z-index: 1;
      padding: 0;
      
      &:not(.v-slide-group--is-overflowing) .v-slide-group__wrapper {
        padding: 0 $ec-spacing-3;
      }
      
      &--show-arrows {
        .v-slide-group__prev,
        .v-slide-group__next {
          min-width: $ec-spacing-4;
          flex-basis: $ec-spacing-4;
        }
      }
      
      .v-slide-group__prev:not(.v-slide-group__prev--disabled):after,
      .v-slide-group__next:not(.v-slide-group__next--disabled):after {
        content: "";
        position: absolute;
        top: 9px;
        bottom: 9px;
        left: 0;
        right: 0;
        width: 40px;
        z-index: 1;
        pointer-events: none;
      }
      
      .v-slide-group__prev:not(.v-slide-group__prev--disabled):after {
        right: unset;
        border-right: 1px solid #C4C9DB;
      }
      
      .v-slide-group__next:not(.v-slide-group__next--disabled):after {
        left: unset;
        border-left: 1px solid #C4C9DB;
      }
      
      .v-slide-group__wrapper {
        .v-tab {
          letter-spacing: 0;
          font-weight: normal;
          text-transform: none;
          text-decoration: none;
          padding: 0 15px;
          
          &::before {
            border-top-right-radius: $ec-form-field-border-radius;
            border-top-left-radius: $ec-form-field-border-radius;
          }
          
          &--active {
            background-color: #FFFFFF;
            border-top-right-radius: $ec-form-field-border-radius;
            border-top-left-radius: $ec-form-field-border-radius;
            z-index: 1;
          }
          
          &:not(.v-tab--active) {
            color: var(--v-text-lighten1);
          }
          
          // No hover effect on active/focus tab
          &--active::before {
            display: none;
          }
          
          // Separator left of tabs
          &::after {
            border-left: 1px solid #C4C9DB;
            content: "";
            position: absolute;
            left: 0;
            height: 30px;
          }
          
          // Separator right of last tab
          &:last-child::after {
            border-right: 1px solid #C4C9DB;
            content: "";
            position: absolute;
            right: 0;
            height: 30px;
          }
          
          // No separator for active tab
          &--active:last-child::after,
          &--active::after {
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
          }
          
          // No separator after active tab element
          &--active + .v-tab::after {
            border-left: 1px solid transparent;
          }
          
          // No separator for hover/focus tab
          &:focus::after,
          &:hover::after {
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
          }
          
          // No separator after hover/focus tab element
          &:focus + .v-tab::after,
          &:hover + .v-tab::after {
            border-left: 1px solid transparent;
          }
        }
      }
      
    }
    
  }
  
  .v-tabs-items {
    overflow: visible;
    border-radius: $ec-default-border-radius;
    position: relative;
    
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      box-shadow: $ec-box-shadow;
      border-radius: $ec-default-border-radius;
    }
    
    .ec-card {
      box-shadow: none;
    }
  }
}
</style>

<template>
  <div class="ec-page-wrapper">
    <div v-if="hasTitle || hasActions" class="ec-page-wrapper-top d-flex justify-space-between ec-mb-3">
      <div v-if="hasTitle">
        <h1>
          <slot name="title"/>
        </h1>
      </div>
      <div v-if="hasActions">
        <ec-card-actions>
          <slot name="actions"/>
        </ec-card-actions>
      </div>
    </div>
    <slot/>
  </div>
</template>

<script>

import EcCardActions from '@/components/EcCardActions.vue'
export default {
  components: {EcCardActions},
  computed: {
    hasTitle() {
      return this.$slots.title
    },
    
    hasActions() {
      return this.$slots.actions
    },
  }
}
</script>

<style lang="scss" scoped>
.ec-page-wrapper {
  // Because of VAppBar in this App
  // margin-top: 15px;
  
  h1 {
    font-family: $ec-heading-font-family;
    font-size: $ec-font-size-1;
    font-weight: $ec-heading-font-weight-bold;
    color: var(--v-text-base);
    margin: 0;
    padding: $ec-spacing-1 0; // Align with actions buttons to the right
  }
}
</style>

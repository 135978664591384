import {AbstractModel} from './AbstractModel'
export class CardInformationModel extends AbstractModel {

    languageCode
    subtitle

    constructor(data = {}) {
        super()
        this.setProperty('languageCode', data.languageCode, '')
        this.setProperty('subtitle', data.subtitle, '')
    }

}

<template>
  <div class="ec-py-2">
    <ec-row class="align-center justify-center ec-width-1200">
      <ec-col class="ec-my-3 col-lg-3 col-md-6 col-sm-6" cols="12">
        <p class="ec-external-browser-header">
          {{ $store.getters.translationsGetter['footer_ec_site_link_header'][$store.getters.languageGetter] }}</p>
        <a href="https://effectconnect.com" target="_blank">www.effectconnect.com</a>
      </ec-col>
      <ec-col class="ec-my-3 col-lg-3 col-md-6 col-sm-6" cols="12">
        <p class="ec-external-browser-header">
          {{ $store.getters.translationsGetter['footer_ec_login_link_header'][$store.getters.languageGetter] }}</p>
        <a href="https://go.effectconnect.com" target="_blank">go.effectconnect.com</a>
      </ec-col>
      <ec-col class="ec-my-3 col-lg-4 col-md-6 col-sm-6" cols="12">
        <p class="ec-external-browser-header">
          {{ $store.getters.translationsGetter['footer_contact_header'][$store.getters.languageGetter] }}</p>
        <a href="tel:0031852088434" target="_blank">+31 (0)85 2088 434</a>
        <span class="ec-ma-2">|</span>
        <a href="mailto:sales@effectconnect.com" target="_blank">sales@effectconnect.com</a>
      </ec-col>
      <ec-col class="ec-my-3 col-lg-2 col-md-6 col-sm-6" cols="12">
        <div class="d-flex">
          <a href="https://www.youtube.com/channel/UCw8nXUs9faFBBO1KqPxN_ZA" target="_blank">
            <ec-icon size="36">mdi-youtube</ec-icon>
          </a>
          <a href="https://www.linkedin.com/company/effectconnect/" target="_blank">
            <ec-icon size="36">mdi-linkedin</ec-icon>
          </a>
          <a href="https://www.facebook.com/effectconnectcom" target="_blank">
            <ec-icon size="36">mdi-facebook</ec-icon>
          </a>
        </div>
      </ec-col>
    </ec-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

.ec-external-browser-header {
  font-family: $ec-heading-font-family;
  font-weight: $ec-heading-font-weight-semi-bold;
  line-height: $ec-font-size-4;
  font-size: $ec-font-size-4;
  color: #737373;
}

.col {
  a {
    color: #737373;
    text-decoration: none;
  }
}

.v-icon {
  color: black;
}

</style>

<script>
import { VCol } from 'vuetify/lib'

export default {
  extends: VCol,
}
</script>

<style lang="scss" scoped>

.col {
  margin: $ec-spacing-4 0 0 0;
  padding: 0 $ec-spacing-3;
}

</style>

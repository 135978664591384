import Vue from 'vue'
import store from './store'
import VueRouter from 'vue-router'
import EcExternalAppStore from '@/pages/EcExternalAppStore.vue'
import EcExternalAppStoreBrowserGroupDetail from '@/pages/EcExternalAppStoreBrowserGroupDetail.vue'
import EcExternalAppDetail from '@/pages/detail/EcExternalAppDetail.vue'
import EcPageNotFound from '@/pages/components/static/EcPageNotFound.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode   : 'history',
    base   : '/',
    routes : [
        {
            path: '/:language/group/:id/:formattedGroupName',
            name: 'EcExternalAppStoreBrowserGroupDetail',
            component: EcExternalAppStoreBrowserGroupDetail,
            props: (route) => ({
                id: parseInt(route.params.id),
                name: String(route.params.name),
                formattedName: String(route.params.formattedName),
                language: String(store.getters.languageGetter),
            })
        },
        {
            path: '/:language/apps/:id/:formattedAppName',
            name: 'EcExternalAppDetail',
            component: EcExternalAppDetail,
            props: (route) => ({
                id: parseInt(route.params.id),
                name: String(route.params.name),
                group: String(route.params.group),
                formattedName: String(route.params.formattedName),
                language: String(store.getters.languageGetter)
            })
        },
        {
            name: '404',
            path: '/:language/404',
            component: EcPageNotFound,
            beforeEnter: (to, from, next) => {
                let array = ['en', 'nl', 'de']
                if (array.indexOf(to.params.language) === -1) {
                    next()
                } else {
                    next()
                }
            },
            props: () => ({
                language: String(store.getters.languageGetter)
            }),
            meta: {
                title: 'EffectConnect - App store',
                metaTags: {
                  title: '',
                  description: ''
                }
            }
        },
        {
            path: '/:language/*',
            redirect: '/:language/404',
            props: () => ({
                language: String(store.getters.languageGetter)
            }),
            meta: {
                title: 'EffectConnect - App store',
                metaTags: {
                  title: '',
                  description: ''
                }
            }
        },
        {
            path     : '/',
            name     : 'ExternalAppStoreRedirect',
            redirect : '/en',
            props: () => ({
                name: 'EffectConnect - App store'
            }),
            meta: {
                title: 'EffectConnect - App store',
                metaTags: {
                    title: '',
                    description: ''
                }
            }
        },
        {
            path: '/:language',
            name: 'ExternalAppStore',
            component: EcExternalAppStore,
            props: () =>({
                language: String(store.getters.languageGetter)
            }),
            meta: {
                title: 'EffectConnect - App store',
                metaTags: {
                    title: '',
                    description: ''
                }
            }
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    Promise.all([store.dispatch('getData'), store.dispatch('getTranslations')]).then(() => {

        let title = null
        let description = store.getters.getTranslation('app_main_meta_description', to.params.language)

        // Add SEO <title> tag
        if (to.name === 'EcExternalAppDetail') {
            if (!store.getters.translatedAppsData.data.find(app => app.id === parseInt(to.params.id))) {
                next({name: '404', params: {language: to.params.language ?? 'en'}})
            } else {
                title = store.getters.getAppDetailField(to.params.id, 'name')
                description = store.getters.getAppDetailField(to.params.id, 'subtitle')
            }
        } else if (to.name === 'EcExternalAppStoreBrowserGroupDetail') {
            if (!store.getters.translatedGroupsData.data.find(group => group.id === parseInt(to.params.id))) {
                next({name: '404', params: {language: to.params.language ?? 'en'}})
            } else {
                title = store.getters.getGroupDetailField(to.params.id, 'information').name
                description = store.getters.getGroupDetailField(to.params.id, 'information').description
            }
        }

        if (title) {
            document.title = title +  ' – EffectConnect App store'
        } else {
            document.title = to.meta.title
        }

        // Add SEO <meta description> tag
        if (!Object.prototype.hasOwnProperty.call(document.getElementsByTagName('meta'), 'description')) {
            let metaTags = document.createElement('meta')
            metaTags.setAttribute('name', 'description')
            metaTags.setAttribute('content', description)
            document.getElementsByTagName('head')[0].appendChild(metaTags)
        } else {
            document.getElementsByTagName('meta')['description'].setAttribute('content', description)
        }

        // Add SEO <link hreflang> tag
        let alternateLinks = document.getElementsByTagName('link')
        store.getters.languagesGetter.forEach(language => {
            Array.from(alternateLinks).forEach((linkTag, index) => {
                if (linkTag.hreflang === language.value) {
                    alternateLinks[index].remove()
                }
            })

            let hrefLang = document.createElement('link')
            if (language.value !== to.params.language) {
                hrefLang.setAttribute('rel', 'alternate')
                hrefLang.setAttribute('hreflang', language.value )
                hrefLang.setAttribute('href', window.location.origin + '/' + to.fullPath.replace(/^.{3}/g, language.value))
                document.getElementsByTagName('head')[0].appendChild(hrefLang)
            }
        })

        next()
        window.trackPage()
    })
})

export default router

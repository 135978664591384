export class AbstractModel {
    setProperty(property, value, defaultValue) {
        if (value !== undefined || defaultValue !== undefined) {
            this[property] = value !== undefined ? value : defaultValue
        //} else {
        //    throw new StructureDataNotValidException(this.constructor.name, property);
        }
    }

    formatPrice (price) {
        if (isNaN(parseFloat(price))) {
            return 0.00
        }
        return parseFloat(price)
    }

    formatInt (value) {
        if (isNaN(parseInt(value))) {
            return 0
        }
        return parseInt(value)
    }

    round (value, digits = 2) {
        return +parseFloat(value).toFixed(digits)
    }
}

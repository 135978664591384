<template>
  <div :class="classes"><slot/></div>
</template>

<script>
export default {
  props: {
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    smallY: {
      type: Boolean,
      default: false
    },
    largeY: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    smallX: {
      type: Boolean,
      default: false
    },
    largeX: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    classes () {
      let classes = ['ec-card-actions']
      if (this.top) {
        classes.push('ec-card-actions-top')
      }
      if (this.bottom) {
        classes.push('ec-card-actions-bottom')
      }
      if (this.left) {
        classes.push('ec-card-actions-left')
      }
      if (this.full) {
        classes.push('ec-card-actions-full')
      }
      if (this.large) {
        classes.push('ec-card-actions-large-y')
      }
      if (this.smallY) {
        classes.push('ec-card-actions-small-y')
      }
      if (this.largeY) {
        classes.push('ec-card-actions-large-y')
      }
      if (this.small) {
        classes.push('ec-card-actions-small-x')
      }
      if (this.smallX) {
        classes.push('ec-card-actions-small-x')
      }
      if (this.largeX) {
        classes.push('ec-card-actions-large-x')
      }
      return classes.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.ec-card-actions {
  display: flex;

  &:not(&-left):not(&-full) {
    justify-content: flex-end;
    &::v-deep > * {
      align-self: center;
      &:not(:last-child) {
        margin-right: $ec-spacing-2;
      }
    }

    &.ec-card-actions-small-x {
      &::v-deep > * {
        &:not(:last-child) {
          margin-right: $ec-spacing-1;
        }
      }
    }

    &.ec-card-actions-large-x {
      &::v-deep > * {
        &:not(:last-child) {
          margin-right: $ec-spacing-3;
        }
      }
    }
  }

  &-left {
    justify-content: flex-start;
    &::v-deep > * {
      align-self: center;
      &:not(:first-child) {
        margin-left: $ec-spacing-2;
      }
    }

    &.ec-card-actions-small-x {
      &::v-deep > * {
        &:not(:first-child) {
          margin-left: $ec-spacing-1;
        }
      }
    }

    &.ec-card-actions-large-x {
      &::v-deep > * {
        &:not(:first-child) {
          margin-left: $ec-spacing-3;
        }
      }
    }
  }

  &-full {
    justify-content: space-between;
  }

  &-top {
    margin-bottom: $ec-spacing-3;
    &.ec-card-actions-large-y {
      margin-bottom: $ec-spacing-4;
    }
    &.ec-card-actions-small-y {
      margin-bottom: $ec-spacing-2;
    }
  }

  &-bottom {
    margin-top: $ec-spacing-3;
    &.ec-card-actions-large-y {
      margin-top: $ec-spacing-4;
    }
    &.ec-card-actions-small-y {
      margin-top: $ec-spacing-2;
    }
  }

  &:not(&-full) .v-input::v-deep {
    flex: 0;

    .v-select__selection--comma {
      overflow: unset;
    }
  }
}
</style>
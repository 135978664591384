<script>
import { VAlert } from 'vuetify/lib'

export default {
  extends: VAlert,

  props: {
    type: {
      type: String,
      default: 'info',
      validator(val) {
        return [
          'info',
          'error',
          'warning',
          'success',
          'gradient',
        ].includes(val)
      }
    },
    noBorderRadius: {
      required: false,
      type: Boolean,
      default: false,
    },
    big: {
      required: false,
      type: Boolean,
      default: false,
    },
    grow: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes () {
      return {
        ...VAlert.options.computed.classes.call(this),
        'ec-alert': true,
        [this.gradientClass]: true,
        'ec-no-border-radius': this.noBorderRadius,
        'ec-alert-big': this.big,
        'ec-alert-grow': this.grow,
      }
    },

    gradientClass () {
      return this.type === 'gradient' ? 'ec-gradient' : 'ec-' + this.type + '-gradient'
    },
  },

  methods: {
    genWrapper () {
      const childrenLeft = [
        this.genSlot('prepend') || this.__cachedIcon,
        this.genContent(),
        this.__cachedBorder,
        this.genSlot('append'),
      ];

      const childrenRight = [
        this.genSlot('pagination'),
        this.$scopedSlots.close
            ? this.$scopedSlots.close({toggle: this.toggle})
            : this.__cachedDismissible,
      ]

      return this.$createElement('div', {staticClass: 'v-alert__wrapper'}, [
        this.$createElement('div', {staticClass: 'ec-alert-wrapper-left'}, childrenLeft),
        this.$createElement('div', {staticClass: 'ec-alert-wrapper-right'}, childrenRight),
      ])
    },

    genSlot (slot) {
      if (this.$slots[slot]) {
        return this.$createElement('div', {staticClass: 'ec-alert-slot-' + slot}, [this.$slots[slot]])
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.ec-alert.v-alert {
  font-size: $ec-font-size-4;
  font-family: $ec-heading-font-family;
  font-weight: $ec-heading-font-weight-semi-bold;
  display: flex;
  margin-bottom: 0;
  border-radius: $ec-default-border-radius;
  padding: $ec-spacing-2 $ec-spacing-3;
  min-height: 50px;

  &.ec-no-border-radius {
    border-radius: 0;
  }

  &.ec-alert-grow {
    .ec-alert-wrapper-left {
      flex-grow: 1;
    }
  }

  .v-alert__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .ec-alert-wrapper-left,
    .ec-alert-wrapper-right {
      align-items: center;
      display: flex;
      height: 100%;
    }

    // Right margin for 'prepend' slot (or alert icon)
    .v-alert__icon,
    .ec-alert-slot-prepend {
      margin-right: $ec-spacing-2;
    }

    // Left margin for 'append' slot
    .ec-alert-slot-append {
      margin-left: $ec-spacing-4;
    }

    .v-alert__icon {
      font-size: 18px;
      align-self: center;
    }

    .v-alert__content {
      display: flex;
      align-items: center;

      .ec-h3 {
        font-size: 20px;
        color: #FFFFFF;
        margin: 0;
        display: inline;
      }
    }

    .ec-alert-slot-pagination {
      height: 100%;
    }

    .ec-text-subtle {
      color: #FFFFFF;
      font-family: $body-font-family;
    }
  }

  &.ec-alert-big {
    padding: $ec-spacing-3;
    min-height: 100px;

    .v-alert__wrapper {
      // Right margin for 'prepend' slot (or alert icon)
      .v-alert__icon,
      .ec-alert-slot-prepend {
        margin-right: $ec-spacing-4;
      }

      // Left margin for 'append' slot
      .ec-alert-slot-append {
        margin-left: $ec-spacing-4;
      }

      .v-alert__icon {
        font-size: 36px;
      }
    }
  }
}
</style>

<script>
import { VContainer } from 'vuetify/lib'

export default {
  extends: VContainer,
}
</script>

<style lang="scss" scoped>

.container {
  padding: 40px;
}

// TODO: the following styles are used as overrides for EC styles, but it might be better to remove them from EC instead?

.container {
  padding: 0;
  margin: 0;
  width: auto;
  background-color: #F2F3F7;
}

</style>

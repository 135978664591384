<template>
  <span class="ec-tooltip" :style="'line-height: ' + lineHeight + ';'">
    <v-tooltip :disabled="disabled" :open-delay="delay" :top="top" :bottom="bottom" z-index="960">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <slot name="activator">
            <ec-icon :class="classes" color="info" v-bind="attrs" v-on="on">icon-ec-question-outlined</ec-icon>
          </slot>
        </span>
      </template>
      <slot name="tooltip">{{ text }}</slot>
      <span class="ec-tooltip__content-arrow"></span>
    </v-tooltip>
  </span>
</template>

<script>

import EcIcon from '@/components/EcIcon.vue'
export default {
  components: {EcIcon},
  
  props: {
    text: {
      required: false,
      type: String,
      default: ''
    },
    classes: {
      required: false,
      type: String,
      default: ''
    },
    delay: {
      required: false,
      type: Number,
      default: 0
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    lineHeight: {
      required: false,
      type: String,
      default: 'inherit'
    },
    top: {
      required: false,
      type: Boolean,
      default: true
    },
    bottom: {
      required: false,
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>
.v-icon {
  cursor: pointer;
  margin-left: $ec-spacing-1;
  line-height: inherit;
}

.v-tooltip__content {
  color: var(--v-text-base);
  background-color: var(--v-ec_background-lighten5);
  padding: $ec-spacing-2;
  border-radius: $ec-default-border-radius;
  box-shadow: $ec-box-shadow;
  white-space: pre-line;

  &:not([class*="leave-active"]) {
    opacity: 1 !important;
  }

  // Disabled for now, because of incorrect positioning at the start/end of page
  //.ec-tooltip__content-arrow {
  //  display: inline-block;
  //  position: absolute;
  //  left: calc(50% - 4px);
  //  bottom: -6px;
  //  width: 0;
  //  height: 0;
  //  border-left: 4px solid transparent;
  //  border-right: 4px solid transparent;
  //  border-top: 6px solid var(--v-ec_background-lighten5);
  //}
}
</style>

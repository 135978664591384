<script>
import {VChip} from 'vuetify/lib'

export default {
  extends: VChip,

  props: {
    small: {
      default: true
    },
    color: {
      default: 'primary'
    },
    overflowVisible: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  computed: {
    classes() {
      return {
        ...VChip.options.computed.classes.call(this),
        'ec-chip': true,
        'ec-overflow-visible': this.overflowVisible,
        'lighten-4': true,
        [this.color + '--text']: ['success', 'warning', 'error', 'primary', 'accent'].includes(this.color),
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.v-chip {
  &.v-chip--active,
  &:not(.v-chip--active) {
    background: var(--v-ec_background-base);
  }

  &.ec-overflow-visible {
    overflow: visible;
  }

  &.v-size--small {
    border-radius: 10px;
    padding: 0 $ec-spacing-2;
    height: auto;
    white-space: normal;

    .v-chip__close.v-icon.v-icon--right {
      font-size: 14px !important;
      margin-left: 7px;
      margin-right: 0;
    }

    .v-chip__content {
      padding: 4px 0;
      font-weight: $ec-body-font-weight-semi-bold;
      font-size: $ec-font-size-5; // 13px
      line-height: $ec-font-size-4; // 14px
    }
  }

  &.default {
    color: var(--v-text-lighten1);
    background-color: var(--v-ec_background_element-base);
  }
}

</style>

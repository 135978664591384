<template>
  <ec-app-store-page-wrapper>
    <template v-slot:content>
      <ec-container fluid>
        <ec-row class="align-center justify-center ec-width-1200">
          <ec-col cols="12">
            <ec-card class="ec-mb-4">
              <ec-splash-image img="404">
                <ec-card-title>{{ $store.getters.getTranslation('404_page_title') }}</ec-card-title>
                <ec-card-text>{{ $store.getters.getTranslation('404_page_subtitle') }}</ec-card-text>
                <ec-btn class="ec-mt-2" color="primary" exact outlined prepend-icon="icon-ec-arrow-left"
                        :to="{name: 'ExternalAppStore', params: {language: $store.getters.languageGetter}}">
                  {{ $store.getters.getTranslation('back_to_store_button_text') }}
                </ec-btn>
              </ec-splash-image>
            </ec-card>
          </ec-col>
        </ec-row>
      </ec-container>
    </template>
  </ec-app-store-page-wrapper>
</template>

<script>

import EcAppStorePageWrapper from '@/pages/components/EcAppStorePageWrapper.vue'

export default {
  components: {EcAppStorePageWrapper}
}

</script>

<style lang="scss" scoped>

.ec-width-1200 {
  //max-width: 1200px;
  margin: 0 auto !important;
}

</style>
